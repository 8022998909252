import * as d3 from 'd3';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap-table/dist/bootstrap-table';
import Node from "./node";
import Network from "./network";
import User from "./user";
import StartApp from "./startAppInfo";
import OAuth from "./oAuth";
import {NODE_TEMPORAL_TYPE, AUTHENTICATION_TYPE, CASE_MANAGER_STATUS, BAYESBOX_MODE} from "./constantsMapping";
import Utilities from "./utilities";
import ZoomNetwork from "./zoomNetwork";
import RightColumn from "./rightColumn";
import Request from "./request";
import CustomClass from "./customClass";

/* global Node, Network, d3, NODE_TEMPORAL_TYPE, User, StartApp, OAuth, AUTHENTICATION_TYPE */

var Cases = (function () {
    var $table = $('#casesTableModal');
    var $addCaseListEvidence = $('#listOfEvidence_addCase');
    var $confirmationDiv = $("#confirmationDeleteCaseContainer");
    var $confirmationMessage = $("#deleteCaseAlertText");
    var $confirmationOK = $("#confirmationDeleteCaseButton_OK");
    var $confirmationCancel = $("#confirmationDeleteCaseButton_Cancel");

    var $generalErrorBox = $("#caseManagerGeneralErrolBox");
    var $generalErrorText = $("#caseManagerGeneralErrorText");

    var $updateCaseForm = $("#updateCaseModalForm");
    var $updateCaseCollapse = $("#updateCaseCollapse");
    var $updateCaseCollapseShow = $("#updateCaseCollapseBtn");
    var $updateCaseCollapse_EvidenceList = $("#listOfEvidence_updateCase");

    var appliedCaseID = null;

    var GEST_NAME = "Guest";

    const CASE_VERSION = 1;

    var columns = function () {
        return [{
            field: 'apply',
            align: 'center',
            events: {
                'click .applyCaseBtn': function (e, value, row, index) {
                    applyCase(row);
                }
            },
            formatter: function (value, row, index, field) {
                var returnHtml = '<div class="btn-group" role="group">';
                var evidence = JSON.parse(row["evidence"]).evidences;
                if (!isDetachedCase(evidence)) {
                    returnHtml += '<a class="applyCaseBtn" href="javascript:void(0)" title="Apply case" style="margin: 2.5px;">' +
                        '<i class="fas fa-play animate-scale1-5" style="color: blue;"></i>' +
                        '</a>';
                }
                returnHtml += "</div>";
                return returnHtml;
            }
        }, {
            field: 'name',
            title: 'Case name',
            sortable: true,
            filterControl: "input"
        }, {
            field: 'category',
            title: 'Category',
            sortable: true,
            filterControl: "select"
        }, {
            field: 'description',
            title: 'Case description',
            sortable: false,
            filterControl: "input"
        }, {
            field: 'evidence',
            title: 'Evidence',
            sortable: false,
            filterControl: "input",
            searchFormatter: false,
            formatter: evidenceFormatter
        }, {
            field: 'userName',
            title: 'User',
            sortable: true,
            filterControl: "select"
        }, {
            field: 'lastModified',
            title: 'Modified',
            sortable: true,
            sorter: function (fieldA, fieldB, rowA, rowB) {
                return fieldA - fieldB;
            },
            formatter: lastModifiedFormatter
        }, {
            field: 'isPrivate',
            title: '',
            formatter: radioFormatter
        }, {
            field: 'remove',
            align: 'center',
            events: {
                'click .removeCase': function (e, value, row, index) {
                    $confirmationMessage.text("Are you sure you want to permanently remove case " + row.name + "?");
                    $confirmationDiv.removeClass("d-none");
                    $confirmationOK.off("click");
                    $confirmationOK.on("click", () => {
                        removeCase(row.id);
                        $confirmationDiv.addClass("d-none");
                    });
                }
            },
            formatter: function (value, row, index, field) {
                var returnHtml = '<div class="btn-group" role="group">';
                var caseOwner = false;
                if (row.user) {
                    caseOwner = (row.user.email === User.getUserId());
                }
                if (User.isAdmin() || caseOwner) {
                    returnHtml += '<a class="removeCase" href="javascript:void(0)" title="Remove case" style="margin: 2.5px;">' +
                        '<i class="fa fa-trash animate-scale1-5" style="color: red;"></i>' +
                        '</a>';
                }
                returnHtml += "</div>";
                return returnHtml;
            }
        }];
    };
    $(document).ready(function () {
        $('#casesModal').on('show.bs.modal', function (e) {
            $table.hide();
            $('#addCaseCollapse').collapse('hide');
            $updateCaseCollapse.collapse('hide');
            $confirmationDiv.addClass("d-none");
            //update booton visible
            if (appliedCaseID !== null && User.isLogged()) {
                var currentCase = $table.bootstrapTable('getRowByUniqueId', appliedCaseID);
                if (currentCase.user && currentCase.user.email === User.getUserId()) {
                    $updateCaseCollapseShow.removeClass("d-none");
                } else {
                    $updateCaseCollapseShow.addClass("d-none");
                }
            } else {
                $updateCaseCollapseShow.addClass("d-none");
            }
            StartApp.runWhenReady(function () {
                //isPrivate checkbox
                $("#addCaseModalForm input[type=checkbox]").prop('checked', true);
                if (!User.isLogged()) {
                    d3.select("#addCaseCollapseBtn").attr("disabled", "");
                    d3.select("#loginCaseBtns").classed("d-none", false);
                    //hide some column for public users
                    var col = columns();
                    columns = () => {
                        return col.filter(function (value, index, arr) {
                            return value.field !== "isPrivate" && value.field !== "userName";
                        });
                    };
                    //disable isPrivate checkbox for unauthorized users
                    $("#addCaseModalForm input[type=checkbox]").prop('disabled', true);
                    //add case button visible
                    if (StartApp.getAuthenticationType() !== AUTHENTICATION_TYPE.NONE) {
                        let caseManagerInfo = StartApp.getCasesManagerInfo();
                        const loginBtnId = "loginCaseBtns";
                        d3.select("#addCaseBtn").select(`#${loginBtnId}`).remove();
                        d3.select("#addCaseBtn")
                            .append("button")
                            .classed("btn btn-primary btn-sm", true)
                            .attr("id", loginBtnId)
                            .text("Log In")
                            .on("click", () => {
                                OAuth.showButtons($("#oauthLoginButtons"), caseManagerInfo.oAuthClients, "");
                                User.setCallbackAfterStandardLogIn(Cases.callbackAfterOauthLogIn);
                                $('#casesModal').modal("hide");
                                $('#oAuthLoginModal').modal("show");
                            });
                    } else {
                        $("#addCaseBtn").addClass("d-none");
                    }
                } else {
                    d3.select("#addCaseCollapseBtn").attr("disabled", null);
                    d3.select("#loginCaseBtns").classed("d-none", true);
                    if (User.isLoginFromOauth()) {
                        $("#addCaseModalForm input[type=checkbox]").prop('disabled', true);
                    } else {
                        $("#addCaseModalForm input[type=checkbox]").prop('disabled', false);
                    }
                }
            }, true);
            loadAllCases();
        });
        $confirmationCancel.on("click",()=>{
            $confirmationDiv.addClass("d-none");
        });
        $("#addCaseModalForm").submit(function (event) {
            //stop submit the form, we will post it manually.
            event.preventDefault();
            var form = $(this);
            // Create an FormData object
            var dataForm = new FormData(form[0]);
            var name = dataForm.get("name");
            if(name === ""){
                $("#nameForm").addClass("is-invalid");
                return;
            }else{
                $("#nameForm").removeClass("is-invalid");
            }
            var category = dataForm.get("category");
            var description = dataForm.get("description");
            var isPrivate = dataForm.get("isPrivate");
            if(isPrivate){
                save(true, name, category, description);
            }else{
                save(false, name, category, description);
            }
        });
        $updateCaseForm.submit(function (event) {
            //stop submit the form, we will post it manually.
            event.preventDefault();
            var currentCase = $table.bootstrapTable('getRowByUniqueId', appliedCaseID);
            update(currentCase);
        });
        $('#addCaseCollapse').on('show.bs.collapse', function (e) {
            $("#addCaseModalForm input[type=text]").val("");
            $("#errorAddCaseModal").addClass("d-none");
            $("#errorUpdateCaseModal").addClass("d-none");
            var evidences = Node.Evidence.getAllEvidence(true);
            var htmlCollapse = getEvidenceCollapse(evidences, "addCaseListEvidence");
            if(htmlCollapse !== ""){
                htmlCollapse = '<p class="font-weight-bold mb-0">Current evidences: </p>' + htmlCollapse;
            }
            $addCaseListEvidence.html(htmlCollapse);
        });
        $updateCaseCollapse.on('show.bs.collapse', function () {
            var currentCase = $table.bootstrapTable('getRowByUniqueId', appliedCaseID);
            $updateCaseCollapse.find("caseTitle").text(currentCase.name);
            var evidences = Node.Evidence.getAllEvidence(true);
            var htmlCollapse = getEvidenceCollapse(evidences, "updateCaseListEvidence");
            if(htmlCollapse !== ""){
                htmlCollapse = '<p class="font-weight-bold mb-0">Current evidences: </p>' + htmlCollapse;
            }
            $updateCaseCollapse_EvidenceList.html(htmlCollapse);
        });
        $updateCaseCollapse_EvidenceList.on('show.bs.collapse', function (e) {
            e.stopPropagation();
        });
        $addCaseListEvidence.on('show.bs.collapse', function (e) {
            e.stopPropagation();
        });
        //set visible case manager
        StartApp.runWhenReady(function () {
            //case manager visible in top menu
            var caseManagerInfo = StartApp.getCasesManagerInfo();
            if (caseManagerInfo.visible === CASE_MANAGER_STATUS.ENABLED_ALL || (caseManagerInfo.visible === CASE_MANAGER_STATUS.ENABLED_AUTHENTICATED && User.isLogged())) {
                $("#caseManagerBtn").addClass("d-inline");
            }else{
                $("#caseManagerBtn").removeClass("d-inline");
            }
        }, false, "caseManagerVisible");
        //close general error
        $generalErrorBox.find(".close").on('click', function (e) {
            $generalErrorBox.addClass("d-none");
        });
    });
    function isValidCase(cases) {
        var allCases = $table.bootstrapTable('getData');
        var validData = {
            valid: false,
            error: ""
        };
        if (User.isLogged()) {
            var userId = User.getUserId();
            validData.valid = !allCases.filter(c => c.user).some(c => c.user.email === userId && c.name === cases.name);
        } else {
            validData.valid = !allCases.some(c => c.name === cases.name);
        }
        if(!validData.valid){
            validData.error = "The case name is used. Set new case name and try again.";
        }
        return validData;
    }
    //add node name to data for filter control
    function preFunctionData(res) {
        for (var i = 0; i < res.length; i++) {
            if(res[i].evidence !== ""){
                var ev = JSON.parse(res[i].evidence);
                var evList = ev.evidences;
                const networkHandle = ev.networkHandle[0]; // for some reason networkHandle is an array - maybe because of multidashboard?
                Network.getCurrentNetwork()
                for (var j = 0; j < evList.length; j++) {
                    const nodeId = Node.getNodeIdFromHandle(evList[j].node);
                    var node = d3.select("#" + nodeId);
                    if (node && node.size() > 0) {
                        evList[j].nodeIDs = node.data()[0].name;
                    } else {
                        evList[j].detachedNode = true;
                    }
                }
                res[i].evidence = JSON.stringify(ev);
            }
            if(res[i].user){
                res[i].userName = res[i].user.lastname + ", " + res[i].user.firstname;
            }else{
                res[i].userName = GEST_NAME;
            }
        }
        return res;
    }
    function radioFormatter(value, row, index) {
        if (row.isPrivate) {
            return [
                '<div title="Private" style="margin: 2.5px;">',
                '<i class="fas fa-lock"></i>',
                '</div>'
            ].join('');
        }else{
            return [
                '<div title="Public" style="margin: 2.5px;">',
                '<i class="fas fa-lock-open"></i>',
                '</div>'
            ].join('');
        }
        return "";
    }
    function lastModifiedFormatter(value, row, index, field) {
        return new Date(row[field]).toLocaleString();
    }
    function evidenceFormatter(value, row, index, field) {
        var evidence = JSON.parse(row[field]).evidences;
        if(isDetachedCase(evidence)){
            return "<span style='color: red;'>This case isn't compatible with version of current network.</span>";
        }
        return getEvidenceCollapse(evidence, row.id);
    }
    function isDetachedCase(evidence) {
        if(!Array.isArray(evidence)){
            return false;
        }
        return evidence.some(ev => {
            if (ev.detachedNode) {
                return true;
            }
            return false;
        });
    }
    function getEvidenceCollapse(evidence, uniqueID){
        var output = '<ul class="list-group list-group-flush collapse" id="collapseEvidenceNameList_'+uniqueID+'">';
        var evidenceNameList = '';
        for (var i = 0; i < evidence.length; i++) {
            const nodeId = Node.getNodeIdFromHandle(evidence[i].node);
            var node = d3.select("#" + nodeId).data()[0];
            evidenceNameList += node.name + ', ';
            var isVirtualEvidence = false;
            var isDynamicEvidence = false;
            if (Array.isArray(evidence[i].evidence)) {
                isVirtualEvidence = true;
                for (var j = 0; j < evidence[i].evidence.length; j++) {
                    if(Array.isArray(evidence[i].evidence[j])){
                        isVirtualEvidence = false;
                        isDynamicEvidence = true;
                    }
                }
            }
            var evidenceHtml = "";
            if (isVirtualEvidence) {
                evidenceHtml = getVirtualEvidenceCollapse(node, evidence[i].evidence, uniqueID);
            } else if (isDynamicEvidence) {
                evidenceHtml = getDynamicEvidenceCollapse(node, evidence[i].evidence, uniqueID);
            } else {
                if (evidence[i].evidenceType === "CONTINUOUS") {
                    evidenceHtml = evidence[i].evidence;
                } else {
                    evidenceHtml = Node.getStateLabels(node)[evidence[i].evidence];
                }
            }
            output += '<li class="list-group-item"><b>' + node.name + ':</b> ' + evidenceHtml + '</li>';
        }
        //remove last ,
        var lastComaIndex = evidenceNameList.lastIndexOf(",");
        var preoutputCollapse = '<a title="Show details" data-toggle="collapse" href="#collapseEvidenceNameList_' + uniqueID + '" role="button" aria-expanded="false" aria-controls="collapseEvidenceNameList_' + uniqueID + '" class="collapsed">' + evidenceNameList.substring(0, lastComaIndex) + ' <i class="fas fa-angle-down"></i></a>';
        output += '</ul>';
        preoutputCollapse += output;
        if(evidenceNameList === ""){
            return "";
        }
        return preoutputCollapse;
    }
    function getVirtualEvidenceCollapse(node, evidence, idRow, time) {
        if (typeof time === "undefined") {
            time = "";
        }
        //outcome: virtual evidence
        var virtualEvidence = "";
        for (var j = 0; j < evidence.length; j++) {
            virtualEvidence += node.outcome[j] + ': ' + evidence[j] + '</br>';
        }
        var nodeID = Node.getNodeIdFromObject(node);
        var html = '<a data-toggle="collapse" href="#collapseEvidenceVirtual' + nodeID + time + idRow + '" role="button" aria-expanded="false" aria-controls="collapseEvidenceVirtual' + nodeID + time + idRow + '" class="collapsed">' + ((time !== "") ? (time + ": ") : ("")) + '(virtual)</a> ' +
            '<div class="collapse" id="collapseEvidenceVirtual' + nodeID + time + idRow + '" style="">' +
            '<div style="padding: 0.5rem;" class="card card-body">' + virtualEvidence + '</div>' +
            '</div>';
        return html;
    }
    function getDynamicEvidenceCollapse(node, evidence, idRow) {
        var dynamicEvidence = "";
        for (var j = 0; j < evidence.length; j++) {
            var time = evidence[j][0];
            var dynamicEvidenceIndex = evidence[j][1];
            if (Array.isArray(dynamicEvidenceIndex)) {//virtual evidence in DBN
                dynamicEvidence += getVirtualEvidenceCollapse(node, dynamicEvidenceIndex, idRow, time);
            } else {
                var evidenceName = node.outcome[dynamicEvidenceIndex];
                dynamicEvidence += time + ": " + evidenceName + '</br>';
            }
        }
        var nodeID = Node.getNodeIdFromObject(node);
        var html = '<a data-toggle="collapse" href="#collapseEvidenceDynamic' + nodeID + time + idRow + '" role="button" aria-expanded="false" aria-controls="collapseEvidenceDynamic' + nodeID + time + idRow + '" class="collapsed">(dynamic)</a> ' +
            '<div class="collapse" id="collapseEvidenceDynamic' + nodeID + time + idRow + '" style="">' +
            '<div style="padding: 0.5rem;" class="card card-body">' + dynamicEvidence + '</div>' +
            '</div>';
        return html;
    }
    function showTitleCase(title, caseID){
        $("#caseManagerBtn text").text(title);
        $("#caseManagerBtn").attr("title", title);
        appliedCaseID = caseID;
    }
    function hideTitleCase() {
        $("#caseManagerBtn text").text("(no case selected)");
        $("#caseManagerBtn").attr("title", "no case selected");
        appliedCaseID = null;
        $updateCaseCollapseShow.addClass("d-none");
    }
    function loadAllCases() {
        $table.bootstrapTable('destroy').bootstrapTable({
            ajax: Request.getCasesNetworks,
            filterControl: true,
            filterShowClear: false,
            uniqueId: "id",
            showRefresh: false,
            classes: "table " + CustomClass.getTableClass(),
            theadClasses: CustomClass.getTableHeadClass(),
            sortable: true,
            flat: true,
            columns: columns(),
            responseHandler: preFunctionData,
            formatNoMatches: function () {
                return 'No cases';
            },
            onLoadSuccess: function (data, status, jqXHR) {
                if(data.length > 0){
                    $table.show();
                }
            }
//            onPostBody: initAllPopovers
        });
    }
    function save(isPrivate, name, category, description) {
        const cases = constructCaseObject(isPrivate, name, category, description);
        const casesValidator = isValidCase(cases);
        if(!casesValidator.valid){
            $("#errorAddCaseModal div").text(casesValidator.error);
            $("#errorAddCaseModal").removeClass("d-none");
            return;
        }
        let done = (id, textStatus, jqXHR) => {
            $('#addCaseCollapse').collapse('hide');
            $table.bootstrapTable('refresh');
            showTitleCase(name, id);
        };
        let fail = function (jqXHR, textStatus, errorThrown) {
            $("#errorAddCaseModal div").text(jqXHR.responseText);
            $("#errorAddCaseModal").removeClass("d-none");
        };
        Request.addCase(cases, done, fail);
    }
    function constructCaseObject(isPrivate, name, category, description) {
        return {
            name,
            category,
            description,
            isPrivate,
            evidence: {
                networkHandle: Network.getCurrentNetwork(),
                evidences: Node.Evidence.getAllEvidence()
            },
            version: CASE_VERSION
        }
    }
    function update(updatedCases) {
        updatedCases.evidence = { // TODO input parameter should not be changed inside the function, because it can be used elsewhere
            networkHandle: Network.getCurrentNetwork(),
            evidences: Node.Evidence.getAllEvidence(),
        };
        let done = (data, textStatus, jqXHR) => {
            $updateCaseCollapse.collapse('hide');
            $table.bootstrapTable('refresh');
        };
        let fail = function (jqXHR, textStatus, errorThrown) {
            $("#errorUpdateCaseModal div").text(jqXHR.responseText);
            $("#errorUpdateCaseModal").removeClass("d-none");
        };
        Request.updateCase(updatedCases, done, fail);
    }
    function removeCase(id) {
        let done = (data, textStatus, jqXHR) => {
            $table.bootstrapTable('refresh');
            if (appliedCaseID === id) {
                hideTitleCase();
            }
        };
        let fail = function (jqXHR, textStatus, errorThrown) {
            $generalErrorText.text(jqXHR.responseText);
            $generalErrorBox.removeClass("d-none");
        };
        Request.deleteCase(id, done, fail);
    }

    /**
     * there was probably a reason why the value of the 'networkHandle' field was replaced with 'networkPath'
     * ON THE SERVER - therefore, the following function is required to maintain backward compatibility
     * @param networkHandleReceivedFromServer
     */
    function getNetworkHandle(networkHandleReceivedFromServer) {
        const receivedType = typeof networkHandleReceivedFromServer;
        if(receivedType === "string") {
            const [category, filename] = networkHandleReceivedFromServer.split("\\");
            return Network.getNetworkHandleData(category, filename);
        } else if (receivedType === "number") {
            return networkHandleReceivedFromServer;
        } else if (receivedType === "object" && typeof networkHandleReceivedFromServer.length !== "undefined") {
            return networkHandleReceivedFromServer[0];
        }
        return null;
    }

    /**
     *
     * @param {type} cases
     * @param {type} setCaseTitle - if set case title in top menu
     * @returns {undefined}
     */
    function applyCase(cases, setCaseTitle, applyCallback) {
        setCaseTitle = typeof setCaseTitle !== 'undefined' ? setCaseTitle : true;
        var evidenceObject = JSON.parse(cases.evidence);
        if(isDetachedCase(evidenceObject)){
            return;
        }
        Node.Evidence.clearAllEvidence();
        for (var i = 0; i < evidenceObject.evidences.length; i++) {
            const nodeId = Node.getNodeIdFromHandle(evidenceObject.evidences[i].node);
            var nodeD3Self = d3.select("#" + nodeId);
            var node = nodeD3Self.data()[0];
            if (typeof node.temporalType !== "undefined") {
                if (node.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                    Node.Evidence.setTemporalEvidence(node, evidenceObject.evidences[i].evidence, false);
                } else {
                    Node.Evidence.setEvidence(nodeD3Self, evidenceObject.evidences[i].evidence, evidenceObject.evidences[i].evidenceType, false);
                }
            } else {
                Node.Evidence.setEvidence(nodeD3Self, evidenceObject.evidences[i].evidence, evidenceObject.evidences[i].evidenceType, false);
            }
        }
        $('#casesModal').modal('hide');
        if (Utilities.isFunction(applyCallback)) {
            applyCallback();
        }
        if (setCaseTitle) {
            Network.update(showTitleCase.bind(null, cases.name, cases.id));
        } else {
            Network.update();
        }
        if (BAYESBOX_MODE.isDashboard()){
            RightColumn.Accordion.refreshCard_G();
        }
    }

    return {
        clean: function () {
            hideTitleCase();
        },
        constructCaseObject: function (isPrivate, name, category, description) {
            return constructCaseObject(isPrivate, name, category, description);
        },
        apply:function (cases, setCaseTitle, applyCallback) {
            applyCase(cases, setCaseTitle, applyCallback);
        },
        callbackAfterOauthLogIn: function () {
            if (!StartApp.isRedirectFromDashboard()) {
                $('#casesModal').modal('show');
            } else {
                ZoomNetwork.zoomFitBoundary();
            }
        }
    };
})();

export default Cases;
