/* global CardsManager, CARDS_TYPE, d3, Node, Network, DISPLAY_NODE_TYPE, Utilities, DynamicBN */

import * as d3 from 'd3';
import {CardsManager} from "./gauge";
import {CARDS_TYPE, DISPLAY_NODE_TYPE} from "./constantsMapping";
import Utilities from "./utilities";
import Network from "./network";
import Node from "./node";
import DynamicBN from "./dynamicBN";

var LineChart = (function () {
    var config = {
        width: 400,
        height: 400,
        chartMargin: ".5rem",
        circleRadius: 7,
        lineWidth: 3,
        pathColor: "steelblue",
        margin: {top: 20, right: 30, bottom: 30, left: 50},
        inverse: false,
        changeDuration: 500,
        yDomainFormat: ".1%"
    };
    var customTip = null;
    function inverseInCard(cardData) {
        let localData = CardsManager.getLocalData(cardData);
        return typeof localData.inverseChart !== "undefined" && localData.inverseChart !== null ? localData.inverseChart : config.inverse;
    }
    function initTip() {
        if(customTip !== null)
            return customTip;
        customTip = d3.select("#content")
                .append("div")
                .attr("id", "lineChTooltip")
                .classed("d3-pie-tip n", true)
                .style("position", "absolute")
                .style("opacity", 1)
                .style("overflow", "scroll")
                .style("max-height", "80vh")
                .style("pointer-events", "none")
                .style("top", 0)
                .style("left", 0)
                .style("box-sizing", "border-box")
                .style("visibility", "hidden")
                .style("transform", "translateY(-20px)");
        return customTip;
    }
    function showTooltip(html, x, y) {
        customTip.style("visibility", "visible")
                .style("pointer-events", "none")
                .style("overflow", null)
                .style("top", y + "px")
                .style("left", x + "px")
                .on("mouseenter", null)
                .on("mouseleave", null);
        customTip.html(html);
    }

    function getCumulativeData(card) {
        let cumulativeValue = inverseInCard(card) ? 1 : 0;
        let series = [];
        const inverseSign = inverseInCard(card) ? -1 : 1;
        const stateLabels = Node.getStateLabels(card.node);
        series.push({
            value: cumulativeValue,
            state: "",
            tooltip: cumulativeValue
        });
        card.node.values.forEach((v, idx, arr) => {
            const state = stateLabels[idx];
            cumulativeValue += inverseSign * v.value;
            series.push({
                value: cumulativeValue,
                state: state,
                tooltip: `P(${stateLabels.slice(0, idx + 1).join("+")})`
            });
        });
        return series;
    }

    function getX(data) {
        return d3.scalePoint()
                .domain(data.map(f => f.state))
                .rangeRound([config.margin.left, config.width - config.margin.right])
                .padding(0);
    }

    function getY(data) {
        return d3.scaleLinear()
                .domain([0, 1]).nice()
                .range([config.height - config.margin.bottom, config.margin.top]);
    }

    function getLine(x, y) {
        return d3.line()
                .defined(d => {
                    return !isNaN(d.value);
                })
                .x(d => x(d.state))
                .y(d => y(d.value));
    }

    function addAll(cards, getDataFunction, configuration) {
        var localConfiguration = {
            tickFormatFunction: undefined
        };
        var prop = undefined;
        for (prop in configuration) {
            localConfiguration[prop] = configuration[prop];
        }
        var scrollContent = CardsManager.getCardBody(cards);
        scrollContent.size() !== 0 ? initTip() : null;
        scrollContent.each((card, index, list) => {
            card.displayNodeType = DISPLAY_NODE_TYPE.LINE_CHART;
            var thisCard = d3.select(list[index]);
            var svgContainer = thisCard.append('div')
                    .attr('class', 'svg-container')
                    .style('padding-bottom', '100%');
            var body = svgContainer.append('svg:svg')
                    .attr('class', 'svg-content')
                    .style("margin", config.chartMargin)
                    .attr('preserveAspectRatio', 'xMinYMin meet')
                    .attr('viewBox', '0 0 ' + config.width + ' ' + config.width);
            var data = [];
            if (Utilities.isFunction(getDataFunction)) {
                data = getDataFunction(card);
            } else {
                data = getCumulativeData(card);
            }
            var x = getX(data);
            var y = getY(data);
            var line = getLine(x, y);
            var axisBottom = d3.axisBottom(x).tickValues(x.domain());
            if(localConfiguration.tickFormatFunction !== undefined){
                axisBottom = d3.axisBottom(x).tickFormat(localConfiguration.tickFormatFunction);
            }
            body.append("g")
                    .attr("transform", "translate(0," + (config.height - config.margin.bottom) + ")")
                    .call(axisBottom);

            body.append("g")
                    .attr("transform", "translate(" + config.margin.left + ",0)")
                    .classed("yAxisCumulative", true)
                    .call(d3.axisLeft(y).tickFormat(d3.format(config.yDomainFormat)));
            var series = body
                    .selectAll(".series")
                    .data(data)
                    .enter()
                    .append("g")
                    .classed("series", true);
            series.append("path")
                    .datum(data)
                    .attr("fill", "none")
                    .attr("stroke", config.pathColor)
                    .attr("stroke-width", config.lineWidth)
                    .attr("stroke-linejoin", "round")
                    .attr("stroke-linecap", "round")
                    .classed("cumulativeLine", true)
                    .attr("d", line);

            series.selectAll("circle")
                    .data(data)
                    .enter().append("circle")
                    .attr("fill", config.pathColor)
                    .attr("r", config.circleRadius)
                    .attr("cx", function (d) {
                        return x(d.state);
                    })
                    .attr("cy", function (d) {
                        return y(d.value);
                    })
                    .on("mouseover", function (d, index, divs) {
                        var html = "<span><b>" + d.value + "</b></span>";
                        if (d.state !== "") {
                            html = "<span>" + d.tooltip + " = <b>" + d.value + "</b></span>";
                        }
                        showTooltip(html, event.pageX, event.pageY);
                        var parentTag = d3.select(divs[index]).node().parentNode;
                        d3.select(parentTag).selectAll("path").style("stroke","red");
                        d3.select(parentTag).selectAll("circle").style("fill","red");
                        d3.select(parentTag).moveToFront();
                    })
                    .on("mouseout", function (d, index, divs) {
                        var parentTag = d3.select(divs[index]).node().parentNode;
                        d3.select(parentTag).selectAll("path").style("stroke",null);
                        d3.select(parentTag).selectAll("circle").style("fill",null);
                        return customTip.style("visibility", "hidden");
                    });
        });
        CardsManager.drawFooterCard(cards, "", {showValue: false});
    }
    function updateValue(cards, getDataFunction) {
        var t = d3.transition()
                .duration(config.changeDuration)
                .ease(d3.easeLinear);
        cards.each((card, index, list) => {
            var data = [];
            if (Utilities.isFunction(getDataFunction)) {
                data = getDataFunction(card);
            } else {
                data = getCumulativeData(card);
            }
            var x = getX(data);
            var y = getY(data);
            var line = getLine(x, y);
            var thisCard = d3.select(list[index]);

            var series = thisCard.selectAll(".series")
                    .data(data);
            series.select(".cumulativeLine")
                    .transition(t)
                    .attr("d", line);
            series.selectAll("circle")
                    .data(d => d)
                    .transition(t)
                    .attr("cx", function (d) {
                        return x(d.state);
                    })
                    .attr("cy", function (d) {
                        return y(d.value);
                    });
            thisCard.select(".svg-content").select(".yAxisCumulative").remove();
            thisCard.select(".svg-content").append("g")
                    .attr("transform", "translate(" + config.margin.left + ",0)")
                    .classed("yAxisCumulative", true)
                    .call(d3.axisLeft(y).tickFormat(d3.format(config.yDomainFormat)));
        });
    }
    return {
        getShortData: function (data, index) {
            var inverseChart = inverseInCard(data);
            var netData = Network.getNetworkData(data.node.network);
            return [CARDS_TYPE.CUMULATIVE, [netData.category, netData.filename, data.node.id], index, data.cumulativeList, CardsManager.getLocalData(data).title, inverseChart ? 1 : 0, data.annotation];
        },
        update: function (cards, getDataFunction) {
            var cardsGroup = CardsManager.detectDisplayNodeType(cards, DISPLAY_NODE_TYPE.LINE_CHART);
            updateValue(cardsGroup.equals, getDataFunction);
            CardsManager.clearCrads(cardsGroup.different);
            if (cardsGroup.different.size() > 0) {
                addAll(cardsGroup.different, getDataFunction);
            }
        },
        removeInexistentCards: function (nodesToRemove) {
            for (var i = 0; i < nodesToRemove.length; i++) {
                d3.select("#gauges").selectAll('div[type="gaugeCard"]')
                        .filter(d =>
                            d.type === CARDS_TYPE.CUMULATIVE &&
                                    d.node.handle === nodesToRemove[i].handle &&
                                    d.node.id === nodesToRemove[i].id &&
                                    d.node.network === nodesToRemove[i].network)
                        .remove();
            }
        },
        decodeShortData: function (shortDat) {
            let nodeIDDat = shortDat[1];
            let networkHandle = Network.getNetworkHandleData(nodeIDDat[0], nodeIDDat[1]);
            let id = Node.getNodeIdFromString(nodeIDDat[2], networkHandle);
            let datNode = d3.select("#" + id).data()[0];
            datNode.cumulativeList = shortDat[3];
            var decodedDat =  {
                type: shortDat[0],
                node: datNode,
                index: CardsManager.getNotNullData(shortDat[2]),
                cumulativeList: shortDat[3],
                annotation: shortDat[6]
            };
            CardsManager.addToLocalDataAndRemoveDefaultIfDefined(decodedDat, {
                title: CardsManager.getNotNullData(shortDat[4]),
                inverseChart: CardsManager.getNotNullData(shortDat[5])
            });
            return decodedDat;
        },
        addAll: function (cards, getDataFunction, configuration) {
            addAll(cards, getDataFunction, configuration);
        },
        isInverse: function () {
            return config.inverse;
        },
        isInverseInCard: function (cardData) {
            return inverseInCard(cardData);
        },
        getDataFromEditLocalCard: function () {
            var editCumulativePanel = d3.select(".cumulativePanel");
            return {
                bottomTitle: editCumulativePanel.select("#cumulativeBottomTitle").property("value"),
                annotation: editCumulativePanel.select(".annotation").property("value"),
                inverse: editCumulativePanel.select("#cumulativeInverse").property("checked")
            };
        }
    };
})();
var LineChartDBN = (function () {
    var getDataFunction = function (card) {
        const states = Node.getStateLabels(card.node);
        let data = [];
        const values = card.node.values;
        let time = 0;
        for (let i = card.selectedStateIndex; i < values.length; i += states.length) {
            if (time >= DynamicBN.stepCount) {
                console.error(`Steps counter from line chart [${time}] is greater than max steps from network [${DynamicBN.stepCount - 1}]`);
            }
            data.push({
                state: time,
                value: values[i].value,
                tooltip: `${states[card.selectedStateIndex]}[t=${time}]`
            });
            time++;
        }
        return data;
    };
    return {
        addAll: function (cards) {
            LineChart.addAll(cards, getDataFunction, {
                tickFormatFunction: (x, y, list) => {
                    var set = new Set(d3.ticks(0, list.length - 1, 5));
                    return set.has(x) ? x : "";
                }
            });
        },
        update: function (cards) {
            LineChart.update(cards, getDataFunction);
        }
    };
})();

export {
    LineChart,
    LineChartDBN
};
