
/* global Network, d3, GenieSymbols, NODE_TEMPORAL_TYPE, NODE_TYPE, Node, Utilities, DynamicBN, Log, Histogram */
import * as d3 from 'd3';
import Utilities from "./utilities";
import {CardsManager, CurrentDashboard} from "./gauge";
import Blur from "./blur";
import {BAYESBOX_MODE, NODE_TEMPORAL_TYPE, NODE_TYPE} from "./constantsMapping";
import Arc from "./arc";
import User from "./user";
import WarningCloseBrowser from "./warningCloseBrowser";
import GenieSymbols from "./genieSymbols";
import Network from "./network";
import DynamicBN from "./dynamicBN";
import Histogram from "./histogram";
import Node from "./node";
import Log from "./log";
import 'hummingbird-treeview';


var RightColumn = {
    CSSselector: "#rightColumn",
    Filter: {
        CSSselector: "#filterBox",
        nodeFilterInput: function (accordionID, filterID) {
            var value = $('#' + filterID).val();
            var cards = d3.selectAll("#" + accordionID).selectAll(".myCard");
            cards.style("display", "none");
            cards.filter(d => Utilities.matcher(d.name, value)).style("display", null);
        },
        /*
         * auto detect filter from HTML file
         * necessary attributes:
         * autoFilter="true" - in main div filter
         * autoFilterListId="nodesToSelect" and type="search" - in input field
         * autoFilterClear="true" - [optional] in clear btn
         * example:
         * <div class="col-md-12 global" id="filterBox">
                            <div class="input-group input-group-sm col-md-12 filterForm" autoFilter="true">
                                <input class="form-control py-2 border-right-0 border" autoFilterListId="nodesToSelect" type="search" placeholder="search">
                                <span class="input-group-append blueButtonGroup">
                                    <button class="btn btn-outline-secondary border-left-0 border" autoFilterClear="true" type="button">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
         */
        autoDetectTreeviewFilter: function (filterToInit) {
            var filters = typeof filterToInit === "undefined" ? d3.selectAll("div[autoFilter='true']") : filterToInit;
//            var filters = d3.selectAll("div[autoFilter='true']");
            filters.each((d, index, nodes) => {
                var inputField = d3.select(nodes[index]).select('input[type="search"]');
                if (inputField.size() === 0) {
                    return;
                }
                inputField.on("keyup", (d, i, divs) => {
                    var value = $(divs[i]).val();
                    var listID = $(divs[i]).attr("autoFilterListId");
                    var hasParents = $('#' + listID + " .autoFilterParent").length > 0;
                    var cards = hasParents ? $('#' + listID + " .autoFilterParent") : $('#' + listID + " .autoFilterNode");
                    RightColumn.Filter.listLIFilter_G(cards, value, hasParents);
                });
                var clearBtn = d3.select(nodes[index]).select('button[autoFilterClear="true"]');
                if (clearBtn.size() === 0) {
                    return;
                }
                clearBtn.data([{self: nodes[index]}])
                        .on("click", (d, i, divs) => {
                            var inputField = $(d.self).find('input[type="search"]');
                            inputField.val("");
                            var listID = inputField.attr("autoFilterListId");
                            var cards = $('#' + listID + " .autoFilterNode");
                            var parents = $('#' + listID + " .autoFilterParent");
                            parents.css("display", "");
                            cards.css("display", "");
                        });
            });
        },
        listLIFilter_G: function (list, value, hasParents) {
            hasParents = typeof hasParents === "undefined" ? false : hasParents;
            if (hasParents) {
                list.each(function (index, elm) {
                    var parentLabel = $(elm).find("label").first();
                    var nodesInParent = $(elm).find(".autoFilterNode");
                    var visibleLeafs = 0;
                    nodesInParent.each((index, nod) => {
                        if (Utilities.matcher($(nod).text(), value)) {
                            $(nod).css("display", "");
                            visibleLeafs++;
                        } else {
                            $(nod).css("display", "none");
                        }
                    });
                    if (visibleLeafs === 0 && Utilities.matcher(parentLabel.text(), value)) {
                        $(elm).css("display", "");
                        nodesInParent.each((index, nod) => $(nod).css("display", ""));
                    } else if (visibleLeafs === 0) {
                        $(elm).css("display", "none");
                    } else {
                        $(elm).css("display", "");
                    }
                });
            } else {
                list.css("display", "none");
                list.each(function (index) {
                    if (Utilities.matcher($(this).text(), value)) {
                        $(this).css("display", "");
                    }
                });
            }
        },
        clearFilterInput_G: function (list, input) {
            input.val("");
            list.css("display", "");
        },
        globalNodeFilterInput: function () {
            var value = $('#filterInput').val();
            // targets
            var cards = d3.selectAll("#targetsAccordion").selectAll(".myCard");
            cards.style("display", "none");
            cards.filter(d => Utilities.matcher(d.name, value)).style("display", null);
            // other
            cards = d3.selectAll("#otherAccordion").selectAll(".myCard");
            cards.style("display", "none");
            cards.filter(d => Utilities.matcher(d.name, value)).style("display", null);
        },
        clearNodeFilterInput: function (accordionID, filterID) {
            $('#' + filterID).val("");
            var cards = d3.selectAll("#" + accordionID).selectAll(".myCard");
            cards.style("display", null);
        },
        globalClearNodeFilterInput: function (accordionID, filterID) {
            $('#filterInput').val("");
            var cards = d3.selectAll("#targetsAccordion").selectAll(".myCard");
            cards.style("display", null);
            cards = d3.selectAll("#otherAccordion").selectAll(".myCard");
            cards.style("display", null);
        },
        PermanentlyHiddenNodes: (function () {
            var settingsBtn = null;

            function fillList() {
                var rootDiv = d3.select("#allNodeList");
                var nodes = [];
                var enableNodes = d3.selectAll("div[type=nodeCard]:not(.hiddenMirrored)")
                        .filter((d, index, nodes) => !d3.select(nodes[index]).classed("systemHidden"));
                enableNodes.each(d => nodes.push(d));
                var getId = function (node, outcome) {
                    return "permanentlyHidden" + node;
                };
                var checked = [];
                enableNodes.filter((d, index, nodes) => !d3.select(nodes[index]).classed("permanentlyHidden")).each(d => checked.push(Node.getNodeIdFromObject(d)));
                CardsManager.createTreeView(rootDiv, nodes, getId, {
                    showOutcomes: false,
                    checkedNodes: checked,
                    showNetworks: false
                });
            }
            function addSettingBtn() {
                settingsBtn = d3.select("#remainingNodes")
                        .append("i")
                        .lower()
                        .attr("id", "showHiddenNodesModal")
                        .style("cursor", "pointer")
                        .classed("fas fa-cog", true)
                        .on("click", () => {
                            Blur.disableUI();
                            $("#permanentlyHiddenModal").modal("show");
                        });
                $('#permanentlyHiddenModal').on('shown.bs.modal', function (e) {
                    $("#filterPermanentlyList").val("");
                    fillList();
                    Blur.enableUI();
                });
            }
            function initSaveBtn() {
                $("#savePermanentlyHidden").on("click", () => {
                    var list = {"id": [], "dataid": [], "text": []};
                    $("#permanentlyHiddenModal #pftreeview").hummingbird("getChecked", {list: list, onlyEndNodes: true});
                    var visibleNodes = [];
                    list.dataid.forEach(id => {
                        let node = d3.select("#" + id).data()[0];
                        visibleNodes.push(node.id);
                    });
                    CurrentDashboard.setVisibleNodes(visibleNodes);
                    CurrentDashboard.updateLocal({
                        updateCards: false,
                        updateOnlyPermanentlyHiddenCards: true
                    });
                    $("#permanentlyHiddenModal").modal("hide");
                });
            }
            function initFilter() {
                d3.select("#filterPermanentlyList")
                        .on("keyup", (data, index, divs) => {
                            var list = $("#permanentlyHiddenModal ul[bayesbox-humminbird-type='list']").find("li");
                            var value = $(divs[index]).val();
                            RightColumn.Filter.listLIFilter_G(list, value);
                        });
                d3.select("#filterPermanentlyClear")
                        .on("click", (data, index, divs) => {
                            var list = $("#permanentlyHiddenModal ul[bayesbox-humminbird-type='list']").find("li");
                            var inputField = $("#filterPermanentlyList");
                            RightColumn.Filter.clearFilterInput_G(list, inputField);
                        });
            }
            function hiddePermanentlyNodes(visibleNodes) {
                //hide all
                d3.selectAll("div[type=nodeCard]").classed("permanentlyHidden", true);
                //show visibleNodes
                visibleNodes.forEach(n => {
                    var mirrorNodes = Node.getMirrorNodes(n);
                    mirrorNodes.forEach(nn => Node.selectNodeFromObject(nn).classed("permanentlyHidden", false));
                });
            }

            function hiddeBySystem(hiddeNodes) {
                hiddeNodes.forEach(nodeDiv => d3.select(nodeDiv).classed("systemHidden", true));
            }
            return {
                init: function () {
                    addSettingBtn();
                    initSaveBtn();
                    initFilter();
                },
                remove: function () {
                    if(settingsBtn !== null){
                        settingsBtn.remove();
                        settingsBtn = null;
                    }
                },
                showVisibleNodesRColumn: function (visibleNodes) {
                    hiddePermanentlyNodes(visibleNodes);
                },
                hiddeBySystem: function (hiddeNodes) {
                    hiddeBySystem(hiddeNodes);
                }
            };
        })()
    },
    Accordion: {
        CSSselector: "#nodesAccordion",
        sortManually: function () {
            d3.select("#otherAccordion").selectAll(".myCard").sort((a, b) => d3.ascending(a.name, b.name));
            d3.select("#targetsAccordion").selectAll(".myCard").sort((a, b) => d3.ascending(a.name, b.name));
        },
        /**
        * Init all accordion cards
        * @param {JSON} data - JSON from /network/get
        */
       initAccordionCards_G: function(unionMap, keepOldNodes) {
           var notRemoveClass = typeof keepOldNodes !== "undefined" && keepOldNodes === true ? "keepOldNodes" : "";
           /*
            Add barchart in right column
            */
            if (notRemoveClass === "") {
                d3.select("#otherAccordion").selectAll(".myCard").remove();
                d3.select("#targetsAccordion").selectAll(".myCard").remove();
            }
           var allNodes = [];
           unionMap.forEach((nodesWithTheSameId, groupID, map) => {
               allNodes = allNodes.concat(nodesWithTheSameId);
           });
            allNodes = allNodes.map(n => {
                n.checkType = function (t) {
                    return Utilities.checkNodeType(this, t);
                };
                return n;
            });
//           data.submodels.forEach(function (d) {
//               allNodes = allNodes.concat(d.nodes);
//            });
            var pins = CurrentDashboard.getPins();
            pins.forEach(p => {
                var node = allNodes.find(function (element) {
                    return element.id === p;
                });
                if(typeof node !== 'undefined' && node !== null){
                    node.pin = true;
                }
            });
            allNodes.sort((a, b) => d3.ascending(a.name, b.name));
           var isSystemHidden = function (node) {
                return node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]);
            };
           //Evidences
           var targetCard = d3.select("#targetsAccordion").selectAll(".myCard"+notRemoveClass)
                   .data(allNodes.filter(function (d) {
                       return typeof d.evidence !== 'undefined';
                   }))
                   .enter()
                   .append("div")
                   .attr('class', (n)=>{
                       return isSystemHidden(n) ? 'card myCard systemHidden' : 'card myCard';
                   })
                   .attr('type', 'nodeCard')
                   .attr('id', d => Node.getNodeIdFromObject(d));
           let showAll = "show";
           Arc.stringToReference();
           this.fillAccordionCards_G(targetCard, showAll);
           d3.select("#targetAccordionTitle").style('display', null);
   //        if (targetCard.node() !== null) {
   //            d3.select("#targetAccordionTitle").style('display', 'block');
   //        } else {
   //            d3.select("#targetAccordionTitle").style('display', 'none');
   //        }
           //Unobserved nodes
           var otherCard = d3.select("#otherAccordion").selectAll(".myCard"+notRemoveClass)
                   .data(allNodes.filter(function (d) {
                       return typeof d.evidence === 'undefined';
                   }))
                   .enter()
                   .append("div")
                   .attr('class', (n)=>{
                       return isSystemHidden(n) ? 'card myCard systemHidden' : 'card myCard';
                   })
                   .attr('type', 'nodeCard')
                   .attr('id', d => Node.getNodeIdFromObject(d));
           showAll = "";
           if (targetCard.data().length === 0) {
               showAll = "show";
           }
           if (notRemoveClass !== "") {
                this.sortManually();
            }
            Arc.stringToReference();
           this.fillAccordionCards_G(otherCard, showAll);
       },
       /**
        *
        * @param {type} id - original node id from data
        * @returns {undefined}
        */
        setPins: function (id, isPin) {
            d3.selectAll("div[type=nodeCard]")
                    .filter(n => n.id === id)
                    .each(n => {
                        if (isPin) {
                            n.pin = true;
                        } else {
                            delete n.pin;
                        }
                    });
        },
       /**
        * @private
        * Fill all (target or other) accordion card
        * Creating target accordion and other nodes accordion depends on card object
        * @param {D3 Object} card - card with data of nodes
        * @param {boolean} show - show card after loaded network
        */
       fillAccordionCards_G: function(card, show) {
           //expand/hide all cards button
           if (card.data().length > 0) {
               if (card.data()[0].isTarget === true) {
                   d3.select('#targetAccordionTitle .expandAll').style('display', null);
               } else {
                   d3.select('#otherAccordionTitle .expandAll').style('display', null);
               }
           }
            var a_link = card.append('div')
                    .attr('class', 'card-header mycard-header d-flex justify-content-between flex-wrap')
                    .attr('role', 'tab')
                    .attr('id', function (d) {
                        return "heading_" + Node.getNodeIdFromObject(d);
                    })
                    .on("mouseenter", function (d, index, divs) {
                        d3.select(divs[index]).select(".hiddenOptions").style("visibility", "visible");
                    })
                    .on("mouseleave", function (d, index, divs) {
                        d3.select(divs[index]).select(".hiddenOptions").style("visibility", null);
                    });
            var colapseTitle = a_link.append('a')
                    .attr('class', 'collapsed cardOutcome')
                    .style("max-width", d => {
                        return d.pin ? "90%" : null;
                    })
                    .attr('data-toggle', 'collapse')
                    .attr('href', function (d) {
                        return '#collapse_' + Node.getNodeIdFromObject(d);
                    })
                    .attr('aria-expanded', 'false')
                    .attr('aria-controls', function (d) {
                        return 'collapse_' + Node.getNodeIdFromObject(d);
                    })
                    .attr('id', function (d) {
                        return 'atext_' + Node.getNodeIdFromObject(d);
                    })
                    .html(function (d) {
                        return d.name;
                    });

            a_link.filter(d => d.pin)
                    .append("div")
                    .classed("hiddenOptions ml-auto", true)
                    .append("i")
                    .classed("fas fa-thumbtack-solid animate-scale1-5", true)
                    .style("padding-right", "0.25rem")
                    .style("cursor", null)
                    .attr("title","Pinned")
                    .style("color", d => {
                        return d.pin ? "#00bfff" : null;
                    })
                    .style("transform", d => {
                        return d.pin ? "rotate(-45deg)" : null;
                    })
                    .style("visibility", d => {
                        return d.pin ? "visible" : null;
                    });

            User.runIfAdminOrExpCategoryAccess(() => {
                colapseTitle.style("max-width", "90%");
                a_link.selectAll(".hiddenOptions").remove();
                a_link.append("div")
                        .classed("hiddenOptions ml-auto", true)
                        .append("i")
                        .classed("fas fa-thumbtack-solid animate-scale1-5", true)
                        .style("padding-right", "0.25rem")
                        .style("cursor", "pointer")
                        .style("color", d => {
                            return d.pin ? "#00bfff" : null;
                        })
                        .style("transform", d => {
                            return d.pin ? "rotate(-45deg)" : null;
                        })
                        .style("visibility", d => {
                            return d.pin ? "visible" : null;
                        })
                        .attr("title", d => {
                            return d.pin ? "Unpin" : "Pin";
                        })
                        .on("click", d => {
                            if (typeof d.pin === 'undefined') {
                                this.setPins(d.id, true);
                            } else {
                                if (d.pin) {
                                    this.setPins(d.id, false);
                                } else {
                                    this.setPins(d.id, true);
                                }
                            }
                            if (d.pin) {
                                d3.select(d3.event.target)
                                        .style("color", "#00bfff")
                                        .style("transform", "rotate(-45deg)")
                                        .attr("title","Unpin")
                                        .style("visibility", "visible");
                            } else {
                                d3.select(d3.event.target)
                                        .style("color", null)
                                        .style("transform", null)
                                        .attr("title","Pin")
                                        .style("visibility", null);
                            }
                            WarningCloseBrowser.enable();
                            RightColumn.Accordion.refreshCard_G();
                        });
            });

           var cardBlock = card.append('div')
                   .attr('id', function (d) {
                       return 'collapse_' + Node.getNodeIdFromObject(d);
                   })
                   .attr('class', 'collapse ' + show)
                   .attr('role', 'tabpanel')
                   .attr('aria-labelledby', function (d) {
                       return 'heading_' + Node.getNodeIdFromObject(d);
                   })
                   .append('div')
                    .attr('class', function (d) {
                        let value = 'card-block mycard-block';
                        if (d.temporalType === NODE_TEMPORAL_TYPE.PLATE || d.checkType(NODE_TYPE.EQUATION)) {
                            value += " pencilMarker";
                        }
                        return value;
                    })
                   .attr('id', function (d) {
                       return 'outcomesBarChart_' + Node.getNodeIdFromObject(d);
                   })
                   .append("ul");

           this.addSingleOutcomes_G(cardBlock);
       },
       /**
        * Add single bar chart to the right column
        * @param {D3 Object} cardBlock - place where will added bar chart
        * @param {array} outcomeData - array with outcomes
        */
       addSingleOutcomes_G: function(cardBlock, outcomeData = null) {
           cardBlock.selectAll('.singleOutcome')
               .data(function (d) {
                   const intervalsExist = typeof(d.intervals) !== "undefined" && d.intervals.length > 0;
                   var outcomes = Node.getStateLabels(d).map((o, idx) => {
                       var dat = {
                           outcome: o,
                           stateIndex: idx,
                           isEvidence: false,
                           nodeD3Self: d3.select(this)
                       };
                       if (typeof d.evidence !== 'undefined') {
                           dat.isEvidence = idx === d.evidence;
                       }
                       return dat;
                   });
                   if (d.temporalType === NODE_TEMPORAL_TYPE.PLATE || d.checkType(NODE_TYPE.EQUATION) || intervalsExist) {
                       outcomes = [{
                           outcome: [],
                           isEvidence: false,
                           isEquation: true,
                           nodeD3Self: d3.select(this),
                           node: {
                               id: Node.getNodeIdFromObject(d),
                               name: d.name
                           }
                       }];
                   }
                   return outcomes;
               })
                   .enter()
                   .append('li')
                   .classed("singleOutcome", true)
                   .text(d => {
                       if(d.nodeD3Self.data()[0].temporalType === NODE_TEMPORAL_TYPE.PLATE){
                           return "Evidence...";
                       }
                       if(!d.isEquation){
                           return d.outcome;
                       }
                       return "Enter Evidence";
                   })
                   .attr('title', 'Click to change evidence')
                   .style('cursor', 'pointer')
                   .style('color', function (d) {
                       if(d.isEvidence){
                           return "green";
                       }
                       return null;
                   })
                   .style('font-weight', function (d) {
                       if(d.isEvidence){
                           return "bold";
                       }
                       return null;
                   })
                    .on('mousedown', function (d) {
                        if (d.nodeD3Self.data()[0].temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                            Node.Evidence.InputTemporalEvidenceModal.showSetingDynamicEvidence(d.nodeD3Self.data()[0]);
                        } else if (!d.isEquation) {
                            d3.event.preventDefault();
                            d3.event.stopPropagation();
                            var nodeAccordion = d3.select(this.parentElement);
                            nodeAccordion.selectAll("li").style("color", null);
                            nodeAccordion.selectAll("li").style("font-weight", null);
                            var nodeObject = nodeAccordion.data()[0];
                            if (nodeObject.evidence === d.stateIndex) {
                                Node.Evidence.clearEvidence(nodeAccordion);
                            } else {
                                Node.Evidence.setEvidence(nodeAccordion, d.stateIndex);
                            }
                            RightColumn.Accordion.refreshCard_G(nodeObject);
                        } else {
                            Node.Evidence.InputEvidenceModal.showEv(d.nodeD3Self, d3.event);
                        }
                    });
        },
        sortRColumn_G: function (accordionIdList) {
            d3.select(accordionIdList)
                    .selectAll("div[type=nodeCard]")
                    .sort((a, b) => {
                        if (a.pin && !b.pin)
                            return -1;
                        if (!a.pin && b.pin)
                            return +1;
                        return d3.ascending(a.name, b.name);
                    });
        },
        refreshCard_G: function (node) {
            var other = d3.select("#otherAccordion").selectAll("div[type=nodeCard]");
            var target = d3.select("#targetsAccordion").selectAll("div[type=nodeCard]");
            GenieSymbols.StatusRColumnIcon.removeAll();
            other.each(d => GenieSymbols.StatusRColumnIcon.setSymbols(d));
            target.each(d => GenieSymbols.StatusRColumnIcon.setSymbols(d));
            if (typeof node !== 'undefined') {
                other = d3.select("#otherAccordion").selectAll("#" + Node.getNodeIdFromObject(node));
                target = d3.select("#targetsAccordion").selectAll("#" + Node.getNodeIdFromObject(node));
            }
            // update evidence in node in observed accordion
            let updateTarget = target.filter(t => (typeof t.evidence !== 'undefined'  || (t.temporalType === NODE_TEMPORAL_TYPE.PLATE && typeof t.temporalEvidence !== 'undefined')) || t.isPropagated);
            updateTarget.each((n, index, divs) => {
                let node = d3.select(divs[index]);
                var ev = n.isPropagated ? n.propagatedOutcomeIds : n.evidence;
                if(Utilities.fieldIsDefinietAndNotNull(ev)){
                    node.select('a[id^="atext_"]').append('selected').text(' (' + ev + ')');
                }
                node.selectAll("li")
                        .style("color", null)
                        .style("font-weight", null)
                        .filter(d => (d.stateIndex === n.evidence) || (n.isPropagated && d.outcome === n.outcome[Node.Evidence.getPropagatedEvidenceIndex(n.values)]))
                        .style("color", 'green')
                        .style("font-weight", 'bold');
            });
            //move node from observed to unobserved
            let targetToOther = target.filter(t => (typeof t.evidence === 'undefined' && typeof t.temporalEvidence === 'undefined')  && !t.isPropagated);
            targetToOther.selectAll("li")
                    .style("color", null)
                    .style("font-weight", null);
            targetToOther.selectAll("selected").remove();
            targetToOther.each((t, index, divs) => {
                $(divs[index]).appendTo('#otherAccordion');
            });
            //move node from unobserved to observed
            let otherToTarget = other.filter(t => (typeof t.evidence !== 'undefined' || (t.temporalType === NODE_TEMPORAL_TYPE.PLATE && typeof t.temporalEvidence !== 'undefined')) || t.isPropagated);
            otherToTarget.each((n, index, divs) => {
                let node = d3.select(divs[index]);
                var ev = n.isPropagated ? n.propagatedOutcomeIds : n.evidence;
                if(Utilities.fieldIsDefinietAndNotNull(ev)){
                    node.select('a[id^="atext_"]').append('selected').text(' (' + ev + ')');
                }
                node.selectAll("li")
                        .filter(d => (d.stateIndex === n.evidence) || (n.isPropagated && d.outcome === n.outcome[Node.Evidence.getPropagatedEvidenceIndex(n.values)]))
                        .style("color", 'green')
                        .style("font-weight", 'bold');
            });
            otherToTarget.each((t, index, divs) => {
                $(divs[index]).appendTo('#targetsAccordion');
            });
            //sort
            this.sortRColumn_G("#targetsAccordion");
            this.sortRColumn_G("#otherAccordion");

        },
        /**
         * Init all accordion cards
         * @param {JSON} data - JSON from /network/get
         * @param {boolean} keepOldNodes - only for dashboards
         */
        initAccordionCards: function (data, keepOldNodes) {
            if (BAYESBOX_MODE.isDashboard()){
                this.initAccordionCards_G(data, keepOldNodes);
                return;
            }
            /*
             Add barchart in right column
             */
            d3.select("#otherAccordion").selectAll(".myCard").remove();
            d3.select("#targetsAccordion").selectAll(".myCard").remove();
            var allNodes = [];
            data.submodels.forEach(function (d) {
                allNodes = allNodes.concat(d.nodes);
            });
            //Targets nodes
            var targetCard = d3.select("#targetsAccordion").selectAll(".myCard")
                    .data(allNodes.filter(function (d) {
                        return d.isTarget;
                    }))
                    .enter()
                    .append("div")
                    .attr('class', 'card myCard');
            let showAll = "show";
            this.fillAccordionCards(targetCard, showAll);
            if (targetCard.node() !== null) {
                d3.select("#targetAccordionTitle").classed("d-flex", true).style('display', null);
                d3.select("#otherNodesTitle").text("Other Nodes");
            } else {
                d3.select("#targetAccordionTitle").classed("d-flex", false).style('display', 'none');
                d3.select("#otherNodesTitle").text("Nodes");
            }
            //Other nodes
            var otherCard = d3.select("#otherAccordion").selectAll(".myCard")
                    .data(allNodes.filter(function (d) {
                        return !d.isTarget;
                    }))
                    .enter()
                    .append("div")
                    .attr('class', 'card myCard');
            showAll = "";
            if(targetCard.data().length === 0){
                showAll = "show";
            }
            if(otherCard.data().length > 0 && otherCard.data()[0].checkType(NODE_TYPE.DEMORGAN)){
                showAll = "";
            }
            this.fillAccordionCards(otherCard, showAll);
//            setTimeout(RightColumn.Accordion.fillAccordionCards, 10, otherCard, "");
        },
        upateDeMorganValue: function () {
            d3.selectAll(".rightArea")
                    .filter(d => d.checkType(NODE_TYPE.DEMORGAN) && typeof d.values !== "undefined")
                    .html((d, index, divs) => {
                        return `<b>${d3.format(".0%")(d.values[0].value)}</b>`;
                    });
        },
        /**
         * @private
         * Fill all (target or other) accordion card
         * Creating target accordion and other nodes accordion depends on card object
         * @param {D3 Object} card - card with data of nodes
         * @param {boolean} show - show card after loaded network
         */
        fillAccordionCards: function (card, show) {
            //expand/hide all cards button
            if (card.data().length > 0) {
                if (card.data()[0].isTarget === true) {
                    d3.select('#targetAccordionTitle .expandAll').style('display', null);
                } else {
                    d3.select('#otherAccordionTitle .expandAll').style('display', null);
                }
            }
            var a_link = card.append('div')
                    .attr('class', 'card-header mycard-header d-flex justify-content-between flex-wrap')
                    .attr('role', 'tab')
                    .attr('id', function (d) {
                        return "heading_" + Node.getNodeIdFromObject(d);
                    });

            a_link.append('a')
                    .attr('class', 'collapsed cardOutcome')
                    .attr('data-toggle', 'collapse')
                    .attr('href', function (d) {
                        return '#collapse_' + Node.getNodeIdFromObject(d);
                    })
                    .attr('aria-expanded', 'false')
                    .attr('aria-controls', function (d) {
                        return 'collapse_' + Node.getNodeIdFromObject(d);
                    })
                    .attr('id', function (d) {
                        return 'atext_' + Node.getNodeIdFromObject(d);
                    })
                    .html(function (d) {
                        if (d.isTarget) {
                            return GenieSymbols.StatusRColumnIcon.targetSymbolHTML + " " + d.name;
                        }
                        return d.name;
                    });

            a_link.append("div")
                    .classed("rightArea ml-auto", true);

            var cardBlock = card.append('div')
                    .attr('id', function (d) {
                        return 'collapse_' + Node.getNodeIdFromObject(d);
                    })
                    .attr('class', 'collapse ' + show)
                    .attr('role', 'tabpanel')
                    .attr('aria-labelledby', function (d) {
                        return 'heading_' + Node.getNodeIdFromObject(d);
                    })
                    .append('div')
                    .attr('class', 'card-block mycard-block')
                    .attr('id', function (d) {
                        return 'outcomesBarChart_' + Node.getNodeIdFromObject(d);
                    });

            RightColumn.Accordion.addSingleBarCharts(cardBlock);
        },
        /**
         * Add single bar chart to the right column
         * @param {D3 Object} cardBlock - place where will added bar chart
         * @param {array} outcomeData - array with outcomes
         */
        addSingleBarCharts: function (cardBlock, outcomeData = null) {
            var singleOutcome = cardBlock.selectAll('.singleOutcomeBarChart')
                    .data(function (d) {
                        if (outcomeData !== null) {
                            let outcomes = d.outcome;
                            if (d.outcome.length === 0 && outcomeData !== null) {
                                outcomes = outcomeData;
                            }
                            //creating valid CSS selector
                            var data = [];
                            if (typeof outcomes === 'undefined' || d.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                                return data;
                            }
                            for (var i = 0; i < outcomes.length; i++) {
                                var selector = outcomes[i];
                                if (d.checkType(NODE_TYPE.EQUATION)) {
                                    selector = Node.Evidence.PREFIX_DISCRETIZATION + Utilities.getValidId(outcomes[i]);
                                }
                                var tmp = {
                                    text: outcomes[i],
                                    cssSelector: selector,
                                    index: i
                                };
                                data[i] = tmp;
                            }
                            return data;
                        } else {
                            const stateLabels = Node.getStateLabels(d);
                            var data = [];
                            if (d.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                                return data;
                            }
                            for (var i = 0; i < stateLabels.length; i++) {
                                var selector = Node.getStateSelector(d, i);
                                if (d.checkType(NODE_TYPE.EQUATION)) {
                                    selector = Node.Evidence.PREFIX_DISCRETIZATION + Utilities.getValidId(stateLabels[i]);
                                }
                                var tmp = {
                                    text: stateLabels[i],
                                    cssSelector: selector,
                                    index: i
                                };
                                data[i] = tmp;
                            }
                            return data;
                        }
                    })
                    .enter()
                    .append('div')
                    .attr('class', 'singleOutcomeBarChart')
                    .attr('title','Click to change evidence')
                    .style('cursor', 'pointer')
                    .on('mousedown', function (d) {
                        d3.event.preventDefault();
                        d3.event.stopPropagation();
                        RightColumn.Accordion.clickCardAccordion(this.parentElement, d.index, d3.event);
                    });
            singleOutcome.append('progresstitle')
                    .text(function (d) {
                        return d.text;
                    })
                    .attr('class', function (d) {
                        return 'progressTitle_' + d.cssSelector;
                    });
            singleOutcome.append('div')
                    .attr('class', 'progress customProgress')//role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                    .append('div')
                    .attr('class', function (d) {
                        return 'progress-bar ' + d.cssSelector;
                    })
                    .attr('role', 'progressbar')
                    .attr('aria-valuenow', 0)
                    .attr('aria-valuemin', 0)
                    .attr('aria-valuemax', 100)
                    .style('width', '0%');
        },
        addMultidimensionalCard: function (nodeData) {
            var nodeID = Node.getNodeIdFromObject(nodeData);
            var indexingParentsIds = nodeData.indexingParentsIds;
            var networkHandle = nodeData.network ? nodeData.network : Network.getCurrentNetwork();
            var card = d3.select('#outcomesBarChart_' + nodeID);
            card.selectAll('.singleOutcomeBarChart').style("display", "none");
            var text = 'The result is a matrix indexed by: ';
            for (var x = 0; x < indexingParentsIds.length; x++) {
                text += Node.getNodeIdFromHandle(parseInt(indexingParentsIds[x].node, 10), networkHandle);
                text += " ";
            }
            text += "? (Click to open Value)";
            card.selectAll('p').remove();
            card.append('p')
                    .attr('class', 'indexed')
                    .on("click", function (d) {
                        d3.event.stopPropagation();
                        Node.Outcome.constructMultidimensionalDiv(d);
                        var translate = Utilities.fitToContainer(`#${Network.getSvgContentId()}`, ".multiDDiv", d3.event.pageX, d3.event.pageY);
                        $('.multiDDiv').css({
                            'left': translate.x,
                            'top': translate.y
                        });
                    })
                    .text(text);
        },
        /**
         * Remove card if necessary for new node (ex. after node update)
         * @param {D3 Object} newNode
         * @returns {RightColumn.Accordion.removeCardIfNecessary.oldCard}
         */
        removeCardIfNecessary: function (newNode) {
            //removing node card if different
            var newNodeID = Node.getNodeIdFromObject(newNode);
            var oldCard = d3.select('#outcomesBarChart_' + newNodeID).selectAll('.singleOutcomeBarChart');
            if (newNode.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU])) {
                if (!RightColumn.Accordion.compareUtilityMauArray(newNodeID, newNode)) {
                    oldCard.remove();
                    oldCard = d3.select('#outcomesBarChart_' + newNodeID).selectAll('.singleOutcomeBarChart');
                }
            } else if (newNode.checkType(NODE_TYPE.EQUATION)) {
                //remove old bar charts
                oldCard.remove();
                oldCard = d3.select('#outcomesBarChart_' + newNodeID).selectAll('.singleOutcomeBarChart');
                //remove old histogram
                d3.select('#outcomesBarChart_' + newNodeID).html("");
            }
            return oldCard;
        },
        /**
         * Add node card if card with barcharts don't added before (while Network.Initialization process)
         * @param {D3 Object} node
         * @returns {undefined}
         */
        addCardIfNecessary: function (node) {
            var nodeID = Node.getNodeIdFromObject(node);
            d3.select("#outcomesBarChart_"+nodeID).classed("mycard-block", true);
            if (node.temporalType === NODE_TEMPORAL_TYPE.PLATE) {
                DynamicBN.AreaChart.createAreaChart(d3.select('#outcomesBarChart_' + nodeID), node, {
                    xAxisClass: "axisAreaChartColor",
                    margin: {
                        right: 15,
                        bottom: 20,
                        left: 15,
                        between: 5
                    }});
            }

            if (node.outcome.length === 0) {
                if (node.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU]) && node.indexingParentsIds.length === 2) {
                    RightColumn.Accordion.addSingleBarCharts(d3.select('#outcomesBarChart_' + nodeID), Node.Outcome.getOutcomeIndexingParents(node, 0));
                } else if (node.checkType(NODE_TYPE.EQUATION)) {
                    if (node.isValueDiscretized || node.values.length === 1) {
                        var labels = [];
                        for (var v = 0; v < node.values.length; v++) {
                            labels[v] = Node.Outcome.getOutcomeIds(node.values[v], node);
                        }
                        RightColumn.Accordion.addSingleBarCharts(d3.select('#outcomesBarChart_' + nodeID), labels);
                    } else {//draw histogram
                        Histogram.createResponsiveHistogram('#outcomesBarChart_' + nodeID, node.values, "100%", nodeID, "", "axisHistogramColor");
                    }
                } else {//probably NODE_TYPE.MAU or ALU without indexing parents
                    RightColumn.Accordion.addSingleBarCharts(d3.select('#outcomesBarChart_' + nodeID), [Node.Outcome.getOutcomeIds(node.values[0], node)]);
                }
            }
        },
        /**
         * Update progress bar in cards
         * @param {type} nodeViewProperties {collapse, progressTitleOutcomeID, value, label, textValue, unit} - from Node.getViewParameter()
         */
        updateProgressBar: function (nodeViewProperties) {
            let progressTitleLabel = typeof nodeViewProperties.label !== "undefined" ? nodeViewProperties.label + ' = ' : "";
            progressTitleLabel += nodeViewProperties.textValue + new String(nodeViewProperties.unit);
            nodeViewProperties.collapse.select('.' + nodeViewProperties.progressTitleOutcomeID)
                    .attr('aria-valuenow', nodeViewProperties.value)
                    .style('width', nodeViewProperties.value + '%');
            nodeViewProperties.collapse.select('progresstitle.progressTitle_' + nodeViewProperties.progressTitleOutcomeID)
                    .text(progressTitleLabel);
        },
        clearProgressBar: function (node) {
            var nodeStates = Node.getStateLabels(node);
            for (var i = 0; i < nodeStates.length; i++) {
                const stateSelector = Node.getStateSelector(node, i);
                var collapse = d3.select('#collapse_' + Node.getNodeIdFromObject(node));
                collapse.select('.' + stateSelector)
                        .attr('aria-valuenow', 0)
                        .style('width', '0%');
                /*
                 update outcome percent in right column
                 */
                collapse.select('progresstitle.progressTitle_' + stateSelector).text(nodeStates[i]);
            }
        },
        Target: {
            expandAll: function () {
                d3.selectAll('#targetsAccordion div[id^=collapse_]').classed('show', true);
            },
            hideAll: function () {
                d3.selectAll('#targetsAccordion div[id^=collapse_]').classed('show', false);
            }
        },
        Other: {
            expandAll: function () {
                d3.selectAll('#otherAccordion div[id^=collapse_]').classed('show', true);
            },
            hideAll: function () {
                d3.selectAll('#otherAccordion div[id^=collapse_]').classed('show', false);
            }
        },
        /**
         * Set evidence by clicking bar chart
         * @param {String} node - node ID
         * @param {String} evidence - evidence ID
         */
        clickCardAccordion: function (nodeSelf, evidence, event) {
            var nodeD3Self = d3.select(nodeSelf);
            var nodeObject = nodeD3Self.data()[0];
            if (nodeObject.checkType(NODE_TYPE.EQUATION)) {
                Node.Evidence.InputEvidenceModal.showEv(nodeD3Self, event);
            } else if (!nodeObject.checkType([NODE_TYPE.UTILITY, NODE_TYPE.MAU])) {
                if (nodeObject.evidence === evidence) {
                    Node.Evidence.clearEvidence(nodeD3Self);
                } else {
                    if (nodeObject.isPropagated !== true) {
                        Node.Evidence.setEvidence(nodeD3Self, evidence);
                    } else {
                        Log.showErrorModal("Propagated evidence!", "Can't set propagated evidence");
                    }
                }
            }
        },
        /*
         Function to update right column for NODE_TYPE.UTILITY and NODE_TYPE.MAU nodes
         Function are comparing current outcomes from node card with new outcomes from update response
         @param {String} nodeID - node id
         @param {array} new_Array - indeksing parents froma server

         return
         true - if outcomes are the same
         false - if outcomes are different
         */
        compareUtilityMauArray: function (nodeID, nodeWithNewArray) {
            var new_Array = nodeWithNewArray.indexingParentsIds;
            if ((typeof new_Array === 'undefined') || new_Array.length === 0) {
                return false;
            }
            var oldArray = $('#outcomesBarChart_' + nodeID + ' progresstitle');
            var newArray = Node.Outcome.getOutcomeIndexingParents(nodeWithNewArray, 0);
            if (newArray.length !== oldArray.length) {
                return false;
            }
            for (var i = 0; i < oldArray.length; i++) {
                var textSplit = oldArray[i].textContent.split(" ");
                var text = "";
                if (textSplit.length > 1) {
                    text = textSplit[0];
                } else {
                    text = textSplit;
                }
                var contain = false;
                for (var j = 0; j < newArray.length; j++) {
                    if (newArray[j] === text) {
                        contain = true;
                        break;
                    }
                }
                if (contain === false) {
                    return false;
                }
            }
            return true;
        },
        displayHiddenCard: function (containerNode, nodeId) {
            var collapseShowed = $('#collapse_' + nodeId).hasClass('show');
            if(!collapseShowed){
                $('#collapse_' + nodeId).addClass('show');
            }
            var rightColumnIsHide = false;
            if ($(RightColumn.CSSselector).css('display') === 'none') {
                $(RightColumn.CSSselector).css('display', 'block');
                rightColumnIsHide = true;
            }
            var cardIsHide = false;
            let otherCards = null;
            let targetCards = null;
            if (!$(containerNode).is(':visible')) {
                otherCards = d3.selectAll("#otherAccordion")
                        .selectAll(".myCard")
                        .filter((d) => {
                            return Node.getNodeIdFromObject(d) === nodeId;
                        });
                otherCards.style("display", null);
                targetCards = d3.selectAll("#targetsAccordion")
                        .selectAll(".myCard")
                        .filter((d) => {
                            return Node.getNodeIdFromObject(d) === nodeId;
                        });
                targetCards.style("display", null);
                cardIsHide = true;
            }
            return {
                nodeId: nodeId,
                rightColumnHideBefore: rightColumnIsHide,
                cardHideBefore: cardIsHide,
                collapseShowedBefore: collapseShowed,
                changedCards: {
                    other: otherCards,
                    target: targetCards
                }
            };
        },
        /**
         *
         * @param {type} state - object returned from displayHiddenCard()
         */
        retractCardStates: function (state) {
            if (state.rightColumnHideBefore) {
                $(RightColumn.CSSselector).css('display', 'none');
            }
            if (state.cardHideBefore) {
                if (state.changedCards.other !== null) {
                    state.changedCards.other.style("display", "none");
                }
                if (state.changedCards.target !== null) {
                    state.changedCards.target.style("display", "none");
                }
            }
            if(!state.collapseShowedBefore){
                $('#collapse_' + state.nodeId).removeClass('show');
            }
        }
    }
};

export default RightColumn;
