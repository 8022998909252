import * as d3 from 'd3';
import Network from "./network";
import Node from "./node";
import Utilities from "./utilities";
import {DISPLAY_NODE_TYPE, CARDS_TYPE } from "./constantsMapping";
import {CardsManager} from "./gauge";

/* global DISPLAY_NODE_TYPE, CARDS_TYPE, d3, PIE_CHART_SORT_TYPE, CardsManager, Node, Network, Utilities */

var PieChart = (function () {
    var config = {
        width: 400,
        height: 400,
        changeDuration: 1000,
        strockeColor: "white",
        legendValueFormat: d3.format('.2%'),
        circleMargin: ".5rem",
        color: {
            spread: 240,
            start: 240,
            brightness: 0.5
        },
        sort: false,
        hideLegend: false
    };
    function getPie() {
        var arcsConfig = d3.pie();
//                    .sort(null)
//                    .padAngle(0);
        return config.sort ? arcsConfig : arcsConfig.sort(null);
    }
    var arc = d3.arc()
            .innerRadius(0)
            .outerRadius(Math.min(config.width, config.height) / 2 - 1);

    function colorRange(index, length, colorProp) {
        var defColor = Utilities.deepCopy(config.color);
        var prop = undefined;
        for (prop in colorProp) {
            defColor[prop] = colorProp[prop];
        }
        var h = (defColor.start + defColor.spread * index / length) % 360;
        return "hsl(" + h + ",100%, " + (defColor.brightness * 100) + "%)";
    }

    var customTip = null;
    function initTip() {
        if(customTip !== null)
            return customTip;
        customTip = d3.select("#content")
                .append("div")
                .attr("id", "pChTooltip")
                .classed("d3-pie-tip n", true)
                .style("position", "absolute")
                .style("opacity", 1)
                .style("overflow", "scroll")
                .style("max-height", "80vh")
                .style("pointer-events", "none")
                .style("top", 0)
                .style("left", 0)
                .style("z-index","1100")
                .style("box-sizing", "border-box")
                .style("visibility", "hidden")
                .style("transform", "translateY(-20px)");
        return customTip;
    }
    var tooltipTimeout = null;
    function showTooltipPieChart(html, x, y) {
        clearTimeout(tooltipTimeout);
        customTip.style("visibility", "visible")
                .style("pointer-events", "none")
                .style("overflow", null)
                .style("top", y + "px")
                .style("left", x + "px")
                .on("mouseenter", null)
                .on("mouseleave", null);
        customTip.html(html);
    }
    function showTooltipLegend(html, x, y) {
        clearTimeout(tooltipTimeout);
        customTip.style("visibility", "visible")
                .style("pointer-events", null)
                .style("overflow", "auto")
                .style("top", y + "px")
                .style("left", x + "px");
        customTip.html(html);
        customTip.on("mouseenter", (d, index, divs) => {
//                    customTip.style("visibility", "visible");
                    return clearTimeout(tooltipTimeout);
                })
                .on("mouseleave", (d, index, divs) => {
                    return customTip.style("visibility", "hidden");
                });
    }
    function addSVGPieCharts(cards, optionsOneOff) {
        cards.size() !== 0 ? initTip() : null;
        var configBackUp = Utilities.deepCopy(config);
        var prop = undefined;
        for (prop in optionsOneOff) {
            config[prop] = optionsOneOff[prop];
        }
        //align-self-start class
        cards.classed("align-self-start", true);

        var svgContainer = cards.append('div')
                .attr('class', 'svg-container')
                .style('padding-bottom', '100%');
        var body = svgContainer.append('svg:svg')
                .attr('class', 'svg-content')
                .style("margin", config.circleMargin)
                .attr('preserveAspectRatio', 'xMinYMin meet')
                .attr('viewBox', '0 0 ' + config.width + ' ' + config.width);
        body.append("g")
                .attr("class", "pathGroup")
                .attr("stroke", config.strockeColor)
                .attr('transform', 'translate(' + config.width / 2 + ',' + config.height / 2 + ')');

        cards.each((card, index, divs) => {
            var dat = card.node.values;
            const nodeStates = Node.getStateLabels(card.node);
            dat.forEach((d, i) => {
                d.node = card.node;
                d.pieOutcome = nodeStates[i];
            });
            var arcs = getPie().value(d => d.value)(dat);
            var color = d3.scaleOrdinal()
                    .domain(card.node.values.map((d, i) => i))
                    .range(card.node.values.map((d, i) => colorRange(i, card.node.values.length)));
            var thisCard = d3.select(divs[index]);

            var paths = thisCard
                    .select(".pathGroup")
                    .selectAll("g")
                    .data(arcs)
                    .enter()
                    .append("g")
                    .append("path")
                    .attr("d", arc)
                    .attr("fill", (d, index) => color(index))
                    .on("mouseover", function (d, index, divs) {
                        d3.select(divs[index]).style("opacity", "50%");
                        showTooltipPieChart("<span>" + d.data.pieOutcome + " - " + d.data.value + "</span>", event.pageX, event.pageY);
                    })
                    .on("mousemove", function () {
                        return customTip.style("top", event.pageY + "px").style("left", event.pageX + "px");
                    })
                    .on("mouseout", function (d, index, divs) {
                        d3.select(divs[index]).style("opacity", null);
                        return customTip.style("visibility", "hidden");
                    })
                    .each(function (d) {
                        this._current = {
                            endAngle: d.endAngle,
                            index: d.index,
                            padAngle: d.padAngle,
                            startAngle: d.startAngle,
                            value: d.value
                        };
                    }); // store the initial angles;

            paths.exit().remove();
            if (!hideLegendInCard(card)) {
                //legend
                var legendItem = thisCard.append("div")
                        .classed("d-flex justify-content-center flex-wrap legendBoody", true)
                        .selectAll(".legendItem")
                        .data(dat)
                        .enter()
                        .append("div")
                        .classed("bd-highlight legendItem", true);
                legendItem.append("div")
                        .classed("rectangleLegend", true)
                        .style("background-color", (d, index) => color(index));
                legendItem.append("span")
                        .classed("legendValue", true)
                        .text((d, i) => d.pieOutcome + " (" + config.legendValueFormat(d.value) + ")");
            } else {//legend in tooltip
                thisCard.select(".svg-container")
                        .append("i")
                        .classed("fa fa-info-circle", true)
                        .style("position","absolute")
                        .style("bottom", "5px")
                        .style("right", "5px")
                        .on("mouseover", (d, index, divs) => {
                            const states = Node.getStateLabels(d.node);
                            const values = d.node.values.map((v) => v.value);
                            var html = "<div style='position: relative;height: 100%;padding-right: 16px;'>";
                            for (var i = 0; i < states.length; i++) {
                                html += "<div style='white-space: nowrap;'>"+
                                        "<div class='rectangleLegend' style='background-color: " + color(i) + ";'></div>"+
                                        '<span class="legendValue">' + states[i] + ' (' + config.legendValueFormat(values[i]) + ')</span>'+
                                        '</div>';
                            }
                            html += '</div>';
                            showTooltipLegend(html, event.pageX, event.pageY);
                            var translate = Utilities.fitToContainer("#content", "#pChTooltip", event.pageX, event.pageY);
                            customTip.style("left", (translate.x) + "px")
                                    .style("top", (translate.y) + "px");
                        })
                        .on("mouseout", function (d, index, divs) {
                            tooltipTimeout = setTimeout(()=>customTip.style("visibility", "hidden"), 1000);
//                            return customTip.style("visibility", "hidden");
                        });
            }
        });
        config = configBackUp;
        return svgContainer;
    }

    function addAll(cards, optionsOneOff) {
        var configBackUp = Utilities.deepCopy(config);
        var prop = undefined;
        for (prop in optionsOneOff) {
            config[prop] = optionsOneOff[prop];
        }
        cards.each(d => d.displayNodeType = DISPLAY_NODE_TYPE.PIE_CHART);
        var scrollCards = CardsManager.getCardBody(cards);
        addSVGPieCharts(scrollCards);
        CardsManager.drawFooterCard(cards, "", {showValue: false});
//        var t = cards.append("div")
//                .attr("class", "card-body text-center");
//        t.append("p")
//                .attr("class", "card-text " + CardsManager.getNodeCaptionClass())
//                .text(d => d.getCurrentTitle());
        config = configBackUp;
    }
    // Store the displayed angles in _current.
    // Then, interpolate from _current to the new angles.
    // During the transition, _current is updated in-place by d3.interpolate.
    function arcTween(a) {
        var specialObject = {
            endAngle: a.endAngle,
            index: a.index,
            padAngle: a.padAngle,
            startAngle: a.startAngle,
            value: a.value
        };
        var i = d3.interpolate(this._current, specialObject);
        this._current = i(0);
        return function (t) {
            return arc(i(t));
        };
    }
    function updateValue(cards) {
        cards.each((card, index, divs) => {
            var dat = card.node.values;
            var thisCard = d3.select(divs[index]);
            const nodeStates = Node.getStateLabels(card.node);
            dat.forEach((d, i) => {
                d.node = card.node;
                d.pieOutcome = nodeStates[i];
            });
            var arcs = getPie().value(d => d.value)(dat);
            thisCard.select(".pathGroup")
                    .selectAll("path")
                    .data(arcs)
                    .transition()
                    .duration(config.changeDuration)
                    .attrTween("d", arcTween);
            thisCard.select(".pathGroup")
                    .selectAll("title")
                    .data(arcs)
                    .text((d, i) => {
                        return d.data.pieOutcome + " - " + d.data.value;
                    });
            if (!hideLegendInCard(card)) {
                thisCard.select(".legendBoody")
                        .selectAll(".legendValue")
                        .data(dat)
                        .text((d, i) => d.pieOutcome + " (" + config.legendValueFormat(d.value) + ")");
            }
        });
    }

    function updateExampleColors(container, colorProp) {
        var card = container.data()[0];
        var color = d3.scaleOrdinal()
                .domain(card.node.values.map((d, i) => i))
                .range(card.node.values.map((d, i) => colorRange(i, card.node.values.length, colorProp)));
        container.select(".pathGroup").selectAll("path").attr("fill", (d, index) => color(index));
    }

    function hideLegendInCard(cardData) {
        let localData = CardsManager.getLocalData(cardData);
        return Utilities.fieldIsDefinietAndNotNull(localData.hideLegend) ? localData.hideLegend : config.hideLegend;
    }
    return {
        addAll: function (cards) {
            addAll(cards);
        },
        update: function (cards) {
            var cardsGroup = CardsManager.detectDisplayNodeType(cards, DISPLAY_NODE_TYPE.PIE_CHART);
            updateValue(cardsGroup.equals);
            CardsManager.clearCrads(cardsGroup.different);
            if (cardsGroup.different.size() > 0) {
                addAll(cardsGroup.different);
            }
        },
        removeInexistentCards: function (nodesToRemove) {
            for (var i = 0; i < nodesToRemove.length; i++) {
                d3.select("#gauges").selectAll('div[type="gaugeCard"]')
                        .filter(d =>
                            d.type === CARDS_TYPE.DISTRIBUTION &&
                                    d.node.handle === nodesToRemove[i].handle &&
                                    d.node.id === nodesToRemove[i].id &&
                                    d.node.network === nodesToRemove[i].network)
                        .remove();
            }
        },
        getShortData: function (data, index) {
            var hideLegend = hideLegendInCard(data);
            var netData = Network.getNetworkData(data.node.network);
            return [CARDS_TYPE.DISTRIBUTION, [netData.category, netData.filename, data.node.id], index, CardsManager.getLocalData(data).title, hideLegend ? 1 : 0, data.annotation];
        },
        decodeShortData: function (shortDat) {
            let nodeIDDat = shortDat[1];
            let networkHandle = Network.getNetworkHandleData(nodeIDDat[0], nodeIDDat[1]);
            let id = Node.getNodeIdFromString(nodeIDDat[2], networkHandle);
            let datNode = d3.select("#" + id).data()[0];
            let distributionDat = {
                type: shortDat[0],
                node: datNode,
                index: CardsManager.getNotNullData(shortDat[2]),
                annotation: shortDat[5]
            };
            CardsManager.addToLocalDataAndRemoveDefaultIfDefined(distributionDat, {
                title: CardsManager.getNotNullData(shortDat[3]),
                hideLegend: CardsManager.getNotNullData(shortDat[4])
            });
            return distributionDat;
        },
        addExamplePieChart: function (divId, id) {
            var dataExample = {
                node: {
                    values: [],
                    outcome: []
                }
            };
            var valuesLength = 7;
            for (var i = 0; i < valuesLength; i++) {
                dataExample.node.values.push({value: 1/valuesLength});
                dataExample.node.outcome.push("example_piechart_" + i);
            }
            d3.selectAll("#" + id).remove();
            var cards = d3.selectAll(divId)
                    .data([dataExample]);
            var svgContainer = addSVGPieCharts(cards, {hideLegend: true});
            svgContainer.attr("id", id);
        },
        updateExampleColors: function (id, colorProp) {
            var container = d3.select("#" + id + " svg");
            updateExampleColors(container, colorProp);
        },
        getColorSpread: function () {
            return config.color.spread;
        },
        getColorSpreadPerc: function (value) {
            value = typeof value === "undefined" ? config.color.spread : value;
            var format = d3.format(".0%");
            return format(value / 360);
        },
        getColorStart: function () {
            return config.color.start;
        },
        getColorStartPerc: function (value) {
            value = typeof value === "undefined" ? config.color.start : value;
            var format = d3.format(".0%");
            return format(value / 360);
        },
        getColorBrightness: function () {
            return config.color.brightness;
        },
        getColorBrightnessPerc: function (value) {
            value = typeof value === "undefined" ? config.color.brightness : value;
            var format = d3.format(".0%");
            return format(value);
        },
        setColor: function (colorProp) {
            if(colorProp === 'undefined' || colorProp === null){
                return ;
            }
            var prop = undefined;
            for (prop in colorProp) {
                config.color[prop] = colorProp[prop];
            }
        },
        getColor: function () {
            return config.color;
        },
        createColorObject: function (spread, start, brightness) {
            if(!config.color.hasOwnProperty("spread") || !config.color.hasOwnProperty("start") || !config.color.hasOwnProperty("brightness")){
                console.log("Color object structure is different!");
            }
            spread = typeof spread === 'undefined' ? config.color.spread : spread;
            start = typeof start === 'undefined' ? config.color.start : start;
            brightness = typeof brightness === 'undefined' ? config.color.brightness : brightness;
            return {
                spread: spread,
                start: start,
                brightness: brightness
            };
        },
        isSort: function () {
            return config.sort;
        },
        setSort: function (type) {
            config.sort = type;
        },
        isHideLegendInCard: function (cardData) {
            return hideLegendInCard(cardData);
        },
        getDataFromEditLocalCard: function () {
            var editPChPanel = d3.select(".distributionPanel");
            return {
                bottomTitle: editPChPanel.select("#distibutionBottomTitle").property("value"),
                annotation: editPChPanel.select(".annotation").property("value"),
                hideLegend: !editPChPanel.select("#showLegendPCh").property("checked")
            };
        }
    };
})();

export default PieChart;
