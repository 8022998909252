
/* global NODE_TEMPORAL_TYPE, d3, ZoomNetwork, d3plus, Arc, Utilities, Geometric, Network, Submodel, Node, RightColumn */

import * as d3 from 'd3';
import {NODE_TEMPORAL_TYPE, NODE_TYPE} from "./constantsMapping";
import ZoomNetwork from "./zoomNetwork";
import Submodel from "./submodel";
import Utilities from "./utilities";
import Geometric from "./geometric";
import RightColumn from "./rightColumn";

var DynamicBN = {
    isDynamicNetwork: false,
    calculatedArcs: [],
    stepCount: 0,
    /**
     * Detecting dynamic network.
     * Function calling while network loading.
     * If any of node is temporal node then network is dynamic.
     * @param {type} node - d3.data()[0]
     */
    detectDynamicNetwork: function (node) {
        this.calculatedArcs = [];
        this.isDynamicNetwork = node.temporalType !== NODE_TEMPORAL_TYPE.CONTEMPORAL;
    },
    detectDynamicNetworkInAvailableNodes: function () {
        var nodes = d3.select("#rightColumn").selectAll('div[type="nodeCard"]').data();
        return nodes.some(n => n.temporalType !== NODE_TEMPORAL_TYPE.CONTEMPORAL);
    },
    detectDynamicNetwork_G: function (addedNetworks) {
        let firstDynamicNetwork = addedNetworks.find(n => typeof n.temporalPlate !== "undefined");
        if (typeof firstDynamicNetwork !== "undefined") {
            this.stepCount = firstDynamicNetwork.temporalPlate.steps;
            this.isDynamicNetwork = true;
        } else if (this.isDynamicNetwork) {
            this.isDynamicNetwork = this.detectDynamicNetworkInAvailableNodes();
        }
    },
    /**
     * Draw temporal plate
     * @param {JSON} temporalPlateProperty - data from /network/get
     * @returns {drawTemporalPlate.temporalPlate}
     */
    drawTemporalPlate: function (temporalPlateProperty) {
        this.stepCount = temporalPlateProperty.steps;
        var strockeWidth = 0.5;
        var temporalPlate = d3.select(ZoomNetwork.getCSSZoomPlaceSelector())
                .select("#" + Submodel.SUBMODEL_PREFIX + Submodel.MAIN_SUBMODEL)
                .append("g")
                .attr("type", "temporalPlate");
        temporalPlate.append("rect")
                .attr("x", temporalPlateProperty.rectPosition.x)
                .attr("y", temporalPlateProperty.rectPosition.y)
                .attr("width", temporalPlateProperty.rectPosition.width)
                .attr("height", temporalPlateProperty.rectPosition.height)
                .attr("fill", "#F4FFF4")
                .attr("rx", 10)//Rounded corner
                .attr("ry", 10)//Rounded corner
                .style("stroke-width", strockeWidth)
                .style("stroke", "black");

        var topHeight = 20;
        var type = "partPlate";
        var temporalPlatePartsProperties = [
            {
                position: "top",
                x: temporalPlateProperty.rectPosition.x,
                y: temporalPlateProperty.rectPosition.y,
                width: temporalPlateProperty.rectPosition.width,
                height: topHeight,
                roundedCorners: [1, 1, 0, 0],
                fill: "#E0E0FF"
            },
            {
                position: "left",
                x: temporalPlateProperty.rectPosition.x,
                y: temporalPlateProperty.rectPosition.y + topHeight,
                width: temporalPlateProperty.leftwidth,
                height: temporalPlateProperty.rectPosition.height - topHeight,
                roundedCorners: [0, 0, 0, 1],
                fill: "#FFFFF0"
            },
            {
                position: "right",
                x: temporalPlateProperty.rectPosition.x + temporalPlateProperty.rectPosition.width - temporalPlateProperty.rightwidth,
                y: temporalPlateProperty.rectPosition.y + topHeight,
                width: temporalPlateProperty.rightwidth,
                height: temporalPlateProperty.rectPosition.height - topHeight,
                roundedCorners: [0, 0, 1, 0],
                fill: "#FFFFF0"
            }
        ];

        temporalPlate.selectAll("path[type=" + type + "]")
                .data(temporalPlatePartsProperties)
                .enter()
                .append("path")
                .attr("type", type)
                .attr("d", (d) => this.pathRoundedRectangle(d.x, d.y, d.width, d.height, d.roundedCorners, 10, 10))
                .attr("fill", (d) => d.fill)
                .style("stroke-width", strockeWidth)
                .style("stroke", "black");

        var textProperties = [
            {
                text: "Init Conditions",
                name: "Init Conditions",
                x: temporalPlateProperty.rectPosition.x,
                y: temporalPlateProperty.rectPosition.y,
                width: temporalPlateProperty.leftwidth},
            {
                text: `Temporal Plate (${this.stepCount} time steps)`,
                name: `Temporal Plate (${this.stepCount} time steps)`,
                x: temporalPlateProperty.rectPosition.x + temporalPlateProperty.leftwidth,
                y: temporalPlateProperty.rectPosition.y,
                width: temporalPlateProperty.rectPosition.width - temporalPlateProperty.leftwidth - temporalPlateProperty.rightwidth},
            {
                text: "Term Conditions",
                name: "Term Conditions",
                x: temporalPlateProperty.rectPosition.x + temporalPlateProperty.rectPosition.width - temporalPlateProperty.rightwidth,
                y: temporalPlateProperty.rectPosition.y,
                width: temporalPlateProperty.rightwidth
            }
        ];
        let rect = {
            x: (d) => d.x,
            y: (d) => d.y,
            width: (d) => d.width,
            height: topHeight
        };
        Utilities.addTextBox(textProperties,
                "middle",
                "bottom",
                rect,
                {"font-size": 12},
                "#414164",
                "g[type=temporalPlate]");

        temporalPlate.moveToBack();
        return temporalPlate;
    },
    /**
     *
     * @param {type} x - left top point X
     * @param {type} y - left top point Y
     * @param {type} width - rectangle width
     * @param {type} height - rectangle height
     * @param {type} roundedCorners - which corners will rounded: 1=rounded; 0=not rounded. [left_top, right_top, right_bottom, left_bottom]. Example: [1,1,0,0] left and right top corners will rounded only
     * @param {type} rx radii of the ellipse
     * @param {type} ry radii of the ellipse
     * @returns {String} - d attribute for path
     */
    pathRoundedRectangle: function (x, y, width, height, roundedCorners, rx, ry) {
        var d = "";
        if (roundedCorners[0] === 1) {//left top corner
            d += "M " + x + "," + (y + ry);
            d += " A " + rx + " " + ry + " 0 0 1 " + (x + rx) + "," + y;
        } else {
            d += "M " + x + "," + y;
        }
        if (roundedCorners[1] === 1) {//right top corner
            d += " H " + (x + width - rx);
            d += " A " + rx + " " + ry + " 0 0 1 " + (x + width) + "," + (y + ry);

        } else {
            d += " H " + (x + width);
            d += " V " + (y + ry);
        }
        if (roundedCorners[2] === 1) {//right bottom corner
            d += " V " + (y + height - ry);
            d += " A " + rx + " " + ry + " 0 0 1 " + (x + width - rx) + "," + (y + height);

        } else {
            d += " V " + (y + height);
        }
        if (roundedCorners[3] === 1) {//left bottom corner
            d += " H " + (x + rx);
            d += " A " + rx + " " + ry + " 0 0 1 " + x + "," + (y + height - ry);

        } else {
            d += " H " + x;
        }
        d += " Z";
        return d;
    },
    calcCurves: function (tail, head, arcs, svgSelector) {
        if (arcs.length === 0) {
            return;
        }

        if (tail.handle === head.handle) {
            var p = 10;
            for (var i = 0; i < arcs.length; i++) {
                if (arcs[i].handle === arcs[i].node.handle && arcs[i].handle === head.handle) {
                    arcs[i].curvePerc = p;
                    p += 15;
                }
            }
        } else {
            switch (arcs.length) {
                case 1:
                    arcs[0].curvePerc = 0;
                    break;
                case 2:
                    arcs[0].curvePerc = 10;
                    arcs[1].curvePerc = -10;
                    break;
                case 3:
                    arcs[0].curvePerc = 0;
                    arcs[1].curvePerc = 20;
                    arcs[2].curvePerc = -20;
                    break;
                default:
                    arcs.sort((lhs, rhs) => {
                        if (lhs.node.handle === rhs.node.handle) {
                            if (lhs.order < rhs.order) {
                                return -1;
                            } else if (lhs.order > rhs.order) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                        if (lhs.node.handle === head.handle) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                    var count = arcs.length;
                    for (var i = 0; i < count; i++) {
                        arcs[i].curvePerc = -30 + 60 * i / (count - 1);
                    }
            }
            for (var i = 0; i < arcs.length; i++) {
                if (arcs[i].node.handle === tail.handle) {
                    arcs[i].curvePerc *= -1;
                }
            }
        }
        let curves = [];
        arcs.forEach(arc => {
            var curvedGeometry = this.calcCurvedGeometry(arc, svgSelector);
            if (!curvedGeometry.ptCircle.circle) {
                let headNode = d3.select("#" + arc.id);
                //transform
                let translateStringParent = Node.selectNodeFromObject(arc.node).attr("transform");
                let translateStringChild = headNode.attr("transform");
                let matrixParent = Utilities.getTranslation(translateStringParent);
                let matrixChild = Utilities.getTranslation(translateStringChild);
                //arc points
                let pointA = Geometric.calculatePoints(arc.node, headNode.data()[0], matrixParent, matrixChild);
                let pointB = Geometric.calculatePoints(headNode.data()[0], arc.node, matrixChild, matrixParent);
                curvedGeometry.ptFrom.x = pointB[0];
                curvedGeometry.ptFrom.y = pointB[1];
                curvedGeometry.ptTo.x = pointA[0];
                curvedGeometry.ptTo.y = pointA[1];
                curvedGeometry.ptZenith.x = (curvedGeometry.ptTo.x + curvedGeometry.ptFrom.x) / 2;
                curvedGeometry.ptZenith.y = (curvedGeometry.ptTo.y + curvedGeometry.ptFrom.y) / 2;
                curvedGeometry.radCircle = 0;
            }
            if (curvedGeometry !== false) {
                curves.push({
                    arc: arc,
                    curvedGeometry: curvedGeometry
                });
            }
        });
        return curves;
    },
    // ***************** Selfish arcs *****************
    SelfishCurve: {
        calcCircularGeometry: function (arc, svgSelector) {
            var curvedGeometry = {
                radCircle: null,
                ptCircle: {},
                ptZenith: {},
                ptFrom: {},
                ptTo: {},
                ptTangent: {},
                ptArrow: {}
            };
            var node = d3.select("#" + arc.id);
            var rcSvg = d3.select(svgSelector).node().getBoundingClientRect();
            var rcNodeReadOnly = node.node().getBoundingClientRect();
            //position on SVG instead Windows position
            var rcNode = {};
            rcNode.top = rcNodeReadOnly.top - rcSvg.top;
            rcNode.right = rcNodeReadOnly.right - rcSvg.left;
            rcNode.bottom = rcNodeReadOnly.bottom - rcSvg.top;
            rcNode.left = rcNodeReadOnly.left - rcSvg.left;
            rcNode.width = rcNodeReadOnly.width;
            rcNode.height = rcNodeReadOnly.height;

            curvedGeometry.radCircle = 20 + arc.curvePerc;
            curvedGeometry.ptCircle = {
                x: rcNode.left + (rcNode.width / 2),
                y: rcNode.top + (rcNode.height / 2)
            };

            curvedGeometry.ptCircle.y = rcNode.bottom + curvedGeometry.radCircle - 20;

            var ptPoints = node.data()[0].flyweight.circleCutSelfishArcs.call(DynamicBN.SelfishCurve, rcNode, curvedGeometry.ptCircle.y, curvedGeometry.radCircle);

            curvedGeometry.ptFrom = Utilities.deepCopy(ptPoints.ptCut1);
            curvedGeometry.ptTo = Utilities.deepCopy(ptPoints.ptCut2);

            curvedGeometry.ptZenith = Utilities.deepCopy(curvedGeometry.ptCircle);
            curvedGeometry.ptZenith.y += curvedGeometry.radCircle;

            curvedGeometry.ptCircle.circle = true;

            return curvedGeometry;
        },
        rectangleCircleCutSelfishArcs: function (rcRectangle, yCircle, radius) {
            var ptCut1 = {
                x: null,
                y: null
            };
            var ptCut2 = {
                x: null,
                y: null
            };
            var ptCircle = {
                x: (rcRectangle.left + rcRectangle.right)/2,
                y: yCircle
            };
            var rcNorm = Utilities.deepCopy(rcRectangle);
            rcNorm.bottom -= ptCircle.y;
            rcNorm.right -= ptCircle.x;
            rcNorm.left -= ptCircle.x;
            rcNorm.top -= ptCircle.y;
            var r2 = radius * radius;
            var x = (0.5 + Math.sqrt(r2 - rcNorm.bottom * rcNorm.bottom));
            if (x > rcNorm.right)
            {
                // enter/exit through vertical sides
                ptCut1.y = ptCut2.y = -(0.5 + Math.sqrt(r2 - rcNorm.left * rcNorm.left));
                ptCut1.x = rcNorm.left;
                ptCut2.x = rcNorm.right;
            } else
            {
                ptCut1.x = -x;
                ptCut2.x = x;
                ptCut1.y = ptCut2.y = rcNorm.bottom;
            }

            ptCut1.x += ptCircle.x;
            ptCut1.y += ptCircle.y;

            ptCut2.x += ptCircle.x;
            ptCut2.y += ptCircle.y;

            return {ptCut1: ptCut1, ptCut2: ptCut2};
        },
        /**
         * @private
         * @param {type} rcEllipse
         * @param {type} yCircle
         * @param {type} radius
         * @returns {DynamicBN.SelfishCurve.ellipseCircleCutSelfishArcs.dynamicBNAnonym$17}
         */
        ellipseCircleCutSelfishArcs: function (rcEllipse, yCircle, radius) {
            var ptCut1 = {
                x: null,
                y: null
            };
            var ptCut2 = {
                x: null,
                y: null
            };
            var ptCenter = {
                x: rcEllipse.left + (rcEllipse.width / 2),
                y: rcEllipse.top + (rcEllipse.height / 2)
            };

            var h = rcEllipse.height;
            var w = rcEllipse.width;

            var rad2 = radius * radius;

            var a = (w / 2) * (w / 2);
            var b = (h / 2) * (h / 2);
            var c = yCircle - ptCenter.y;

            var A = a - b;
            var B = -2 * a * c;
            var C = a * c * c - a * b + b * rad2;

            var y;
            if (A === 0) {
                //B*y+C=0
                y = (-C) / B;
            } else {
                // A*y^2 + B*y + C = 0
                var delta = B * B - 4 * A * C;
                y = (-B - Math.sqrt(delta)) / (2 * A);
            }
            var x = Math.sqrt(rad2 - y * y);

            ptCut2.x = 0.5 + x;
            ptCut1.x = -ptCut2.x;
            ptCut1.y = ptCut2.y = -(0.5 + y);

            ptCut1.x += ptCenter.x;
            ptCut1.y += yCircle;

            ptCut2.x += ptCenter.x;
            ptCut2.y += yCircle;

            return {ptCut1: ptCut1, ptCut2: ptCut2};
        }
    },
    // ***************** Normal temporal arcs and selfish arcs *****************
    calcCurvedGeometry: function (arc, svgSelector) {
        if (arc.handle === arc.node.handle) {
            return this.SelfishCurve.calcCircularGeometry(arc, svgSelector);
        }
        var curvedGeometry = {
            radCircle: null,
            ptCircle: {},
            ptZenith: {},
            ptFrom: {},
            ptTo: {},
            ptTangent: {},
            ptArrow: {}
        };
        var rcSvg = d3.select(svgSelector).node().getBoundingClientRect();
        var rcTailReadOnly;
        var tailNode;
        //detect submodels
        if (typeof arc.node.submodelOfSubmodel !== "undefined") {
            rcTailReadOnly = d3.select("#" + Submodel.SUBMODEL_RECTANGLE_PREFIX + arc.node.handle).node().getBoundingClientRect();
            tailNode = d3.select("#" + Submodel.SUBMODEL_RECTANGLE_PREFIX + arc.node.handle);
        } else {
            tailNode = Node.selectNodeFromObject(arc.node);
            rcTailReadOnly = tailNode.node().getBoundingClientRect();
        }

        //position on SVG instead Windows position
        var rcTail = {};
        rcTail.top = rcTailReadOnly.top - rcSvg.top;
        rcTail.right = rcTailReadOnly.right - rcSvg.left;
        rcTail.bottom = rcTailReadOnly.bottom - rcSvg.top;
        rcTail.left = rcTailReadOnly.left - rcSvg.left;
        rcTail.width = rcTailReadOnly.width;
        rcTail.height = rcTailReadOnly.height;

        var headNode = d3.select("#" + arc.id);
        var rcHeadReadOnly = headNode.node().getBoundingClientRect();
        //position on SVG instead Windows position
        var rcHead = {};
        rcHead.top = rcHeadReadOnly.top - rcSvg.top;
        rcHead.right = rcHeadReadOnly.right - rcSvg.left;
        rcHead.bottom = rcHeadReadOnly.bottom - rcSvg.top;
        rcHead.left = rcHeadReadOnly.left - rcSvg.left;
        rcHead.width = rcHeadReadOnly.width;
        rcHead.height = rcHeadReadOnly.height;

        var pt1 = {
            x: rcHead.left + (rcHead.width / 2),
            y: rcHead.top + (rcHead.height / 2)
        };
        var pt2 = {
            x: rcTail.left + (rcTail.width / 2),
            y: rcTail.top + (rcTail.height / 2)
        };
        var ptMid = {
            x: (pt1.x + pt2.x) / 2,
            y: (pt1.y + pt2.y) / 2
        };
        var pt0 = {
            x: ptMid.x - arc.curvePerc * (pt1.y - ptMid.y) / 100,
            y: ptMid.y + arc.curvePerc * (pt1.x - ptMid.x) / 100
        };
        curvedGeometry.ptCircle = this.findCircle(pt0, pt1, pt2);
        if (!curvedGeometry.ptCircle.circle) {
            return curvedGeometry;
        }

        curvedGeometry.radCircle = (0.5 + Math.sqrt((Math.pow(curvedGeometry.ptCircle.x - pt0.x, 2) + Math.pow(curvedGeometry.ptCircle.y - pt0.y, 2))));

        var headCircleCut = headNode.data()[0].flyweight.circleCutArcs.call(DynamicBN, rcHead, pt2, curvedGeometry.ptCircle);//tutaj
        if (!headCircleCut.icon) {
            return false;
        }
        delete headCircleCut.icon;
        var ptCut1 = Utilities.deepCopy(headCircleCut);
        var tailCircleCut = tailNode.data()[0].flyweight.circleCutArcs.call(DynamicBN, rcTail, pt1, curvedGeometry.ptCircle);
        if (!tailCircleCut.icon) {
            return false;
        }
        delete tailCircleCut.icon;
        var ptCut2 = Utilities.deepCopy(tailCircleCut);

        var ptArrow = Utilities.deepCopy(ptCut1);
        if (arc.curvePerc < 0) {
            let ptTmp = ptCut1;
            ptCut1 = ptCut2;
            ptCut2 = ptTmp;
        }

        var ptn1 = Utilities.deepCopy(ptCut2);
        ptn1.x -= curvedGeometry.ptCircle.x;
        ptn1.y -= curvedGeometry.ptCircle.y;

        var ptn2 = Utilities.deepCopy(ptCut1);
        ptn2.x -= curvedGeometry.ptCircle.x;
        ptn2.y -= curvedGeometry.ptCircle.y;

        var sign = ptn2.x * ptn1.y - ptn2.y * ptn1.x;

        if (0 === sign) {
            return false;
        } else if (sign < 0) {
            // switch from/to
            curvedGeometry.ptFrom = Utilities.deepCopy(ptCut1);
            curvedGeometry.ptTo = Utilities.deepCopy(ptCut2);
        } else {
            curvedGeometry.ptFrom = Utilities.deepCopy(ptCut2);
            curvedGeometry.ptTo = Utilities.deepCopy(ptCut1);
        }

        curvedGeometry.ptZenith = this.getPtZenit(Utilities.deepCopy(curvedGeometry.ptFrom), Utilities.deepCopy(curvedGeometry.ptTo), Utilities.deepCopy(curvedGeometry.ptCircle), curvedGeometry.radCircle);

        var ptExt = Utilities.deepCopy(ptArrow);
        ptExt.x -= curvedGeometry.ptCircle.x;
        ptExt.y -= curvedGeometry.ptCircle.y;
        var tmp = ptExt.x;
        ptExt.x = -ptExt.y;
        ptExt.y = tmp;

        if (arc.curvePerc < 0)
        {
            ptExt.x = -ptExt.x;
            ptExt.y = -ptExt.y;
        }

        ptExt.x += ptArrow.x;
        ptExt.y += ptArrow.y;

        curvedGeometry.ptTangent = Utilities.deepCopy(ptExt);

        return curvedGeometry;
    },
    /**
     * @private
     * @param {type} ptFrom - arc start point
     * @param {type} ptTo - arc end point
     * @param {type} ptCircle - center of circle
     * @param {type} radCircle - circle radius
     * @returns {Object|getPtZenit.ptZenith}
     */
    getPtZenit: function (ptFrom, ptTo, ptCircle, radCircle) {
        var ptZenith = new Object();
        var ptAVG = {
            x: (ptFrom.x + ptTo.x) / 2,
            y: (ptFrom.y + ptTo.y) / 2
        };
        var lenPtCenterPtAVG = Math.sqrt((ptAVG.x - ptCircle.x) * (ptAVG.x - ptCircle.x) + (ptAVG.y - ptCircle.y) * (ptAVG.y - ptCircle.y));
        var extendLen = radCircle - lenPtCenterPtAVG;

        ptZenith.x = ptAVG.x + extendLen / lenPtCenterPtAVG * (ptAVG.x - ptCircle.x);
        ptZenith.y = ptAVG.y + extendLen / lenPtCenterPtAVG * (ptAVG.y - ptCircle.y);

        return ptZenith;
    },
// ***************** Normal temporal arcs *****************
    /**
     * @private
     * @param {type} a
     * @param {type} b
     * @param {type} c
     * @returns {DynamicBN.findCircle.dynamicBNAnonym$15|DynamicBN.findCircle.dynamicBNAnonym$16}
     */
    findCircle: function (a, b, c) {
        var A = b.x - a.x;
        var B = b.y - a.y;
        var C = c.x - a.x;
        var D = c.y - a.y;

        var E = A * (a.x + b.x) + B * (a.y + b.y);
        var F = C * (a.x + c.x) + D * (a.y + c.y);

        var G = 2 * (A * (c.y - b.y) - B * (c.x - b.x));

        if (0 === G) {
            return {x: null, y: null, circle: false};
        }
        return {x: ((D * E - B * F) / G), y: ((A * F - C * E) / G), circle: true};
    },
    rectangleCircleCut: function (rcRectangle, ptOther, ptCircle) {
        var rcNorm = Utilities.deepCopy(rcRectangle);
        rcNorm.bottom -= ptCircle.y;
        rcNorm.right -= ptCircle.x;
        rcNorm.left -= ptCircle.x;
        rcNorm.top -= ptCircle.y;
        var ptCenter = {
            x: rcNorm.left + (rcNorm.width / 2),
            y: rcNorm.top + (rcNorm.height / 2)
        };
        var r2 = Math.pow(ptCenter.x, 2) + Math.pow(ptCenter.y, 2);

        var ptOut = [];
        ptOut = ptOut.concat(this.circHelper(r2, rcNorm.left, rcNorm.top, rcNorm.bottom, false));
        ptOut = ptOut.concat(this.circHelper(r2, rcNorm.right, rcNorm.top, rcNorm.bottom, false));
        ptOut = ptOut.concat(this.circHelper(r2, rcNorm.top, rcNorm.left, rcNorm.right, true));
        ptOut = ptOut.concat(this.circHelper(r2, rcNorm.bottom, rcNorm.left, rcNorm.right, true));

        var minDist = -1;
        var ptCut = {};
        for (var i = 0; i < ptOut.length; i++) {
            var pt = Utilities.deepCopy(ptOut[i]);
            pt.x += ptCircle.x;
            pt.y += ptCircle.y;

            var dist = Math.pow(pt.x - ptOther.x, 2) + Math.pow(pt.y - ptOther.y, 2);
            if(-1 === minDist || dist < minDist){
                ptCut = Utilities.deepCopy(pt);
                minDist = dist;
            }
        }
        ptCut.icon = (-1 !== minDist);
        return ptCut;
    },
    circHelperCheckBetween: function (fixed, value, lo, hi, reverse) {
        var out = {};
        if(value >= lo && value <= hi){
            if(reverse){
                out.x = value;
                out.y = fixed;
            }else{
                out.x = fixed;
                out.y = value;
            }
        }
        return out;
    },
    circHelper: function (r2, fixed, lo, hi, reverse) {
        var out = [];
        var diff = r2 - Math.pow(fixed, 2);
        if (diff >= 0) {
            var v = 0.5 + Math.sqrt(diff);
            var singleOut = this.circHelperCheckBetween(fixed, v, lo, hi, reverse);
            if (!$.isEmptyObject(singleOut)) {
                out.push(singleOut);
            }
            singleOut = this.circHelperCheckBetween(fixed, -v, lo, hi, reverse);
            if (!$.isEmptyObject(singleOut)) {
                out.push(singleOut);
            }
        }
        return out;
    },
    /**
     * @private
     * @param {type} rcEllipse
     * @param {type} ptSecondEllipse
     * @param {type} ptCircle
     * @returns {DynamicBN.ellipseCircleCut.ptCut}
     */
    ellipseCircleCut: function (rcEllipse, ptSecondEllipse, ptCircle) {
        var a = Math.pow(rcEllipse.width / 2, 2);
        var b = Math.pow(rcEllipse.height / 2, 2);

        var ptEllipCenter = {
            x: rcEllipse.left + (rcEllipse.width / 2),
            y: rcEllipse.top + (rcEllipse.height / 2)
        };

        var c = ptCircle.x - ptEllipCenter.x;
        var d = ptCircle.y - ptEllipCenter.y;

        // things get unstable with d == 0 (y gets completely eliminated)
        // TODO: consider switching to quadratic equation in this case (also for c == 0?)
        if (0 === d) {
            d = 1;
        }
        var z4 = Math.pow((a - b), 2);
        var z3 = -4.0 * (a - b) * a * c;
        var z2 = a * b * (4.0 * Math.pow(d, 2) + 2 * (a - b)) + 4 * Math.pow(a, 2) * Math.pow(c, 2);
        var z1 = -4.0 * Math.pow(a, 2) * b * c;
        var z0 = Math.pow(a, 2) * (Math.pow(b, 2) - 4.0 * b * Math.pow(d, 2));

        var solution = Geometric.ExternalEquations.quartic([z0, z1, z2, z3, z4]);
        var out = solution.sol;

        var otherSign = Math.sign(d * (ptSecondEllipse.x - ptEllipCenter.x) - c * (ptSecondEllipse.y - ptEllipCenter.y));
        var ptCut = {
            x: null,
            y: null,
            icon: null
        };
        for (var i = 0; i < solution.Nsol; i++) {
            var x = out[i];
            var y = Math.sqrt(b - Math.pow(x, 2) * b / a);
            var diffCommon = Math.pow((x - c), 2) - Math.pow(c, 2) - Math.pow(d, 2);
            var diff1 = Math.abs(Math.pow((+y - d), 2) + diffCommon);
            var diff2 = Math.abs(Math.pow((-y - d), 2) + diffCommon);
            if (diff2 < diff1) {
                y = -y;
            }
            var psign = Math.sign(d * x - c * y);
            if (psign === otherSign) {
                ptCut.x = x;
                ptCut.y = y;
                ptCut.icon = true;
                ptCut.x += ptEllipCenter.x;
                ptCut.y += ptEllipCenter.y;
                return ptCut;
            }
        }
        ptCut.x = ptEllipCenter.x;
        ptCut.y = ptEllipCenter.y;
        ptCut.icon = false;
        return ptCut;
    },
    rightTemporalMenu: function (data) {
        var menu = [{
                title: 'Evidence...',
                action: function (elm, d, title) {
                    Node.Evidence.InputTemporalEvidenceModal.showSetingDynamicEvidence(elm);
                }
            }, {
                divider: true
            }, {
                title: 'Show Definition',
                action: function (elm) {
                    Node.showDefinition(elm, Math.round(d3.event.pageX), Math.round(d3.event.pageY));
                },
                disabled: function (elm) {
                    return elm.checkType(NODE_TYPE.DEMORGAN);
                }
            }];
        return menu;
    },
    removeDynamicGradient: function () {
        d3.selectAll("linearGradient[dynamicGradient='true']").remove();
    },
    updateDeMorganGradient: function (nodeDat, qualColor) {
        let fillFunction = function (percentage, isReverseColor) {
            var colors = qualColor;
            var c1 = colors[0];
            var c2 = colors[1];
            var c3 = colors[2];
            if (isReverseColor) {
                return Utilities.blend_colors([c3, c2, c1], percentage);
            }
            return Utilities.blend_colors([c1, c2, c3], percentage);
        };
        if(typeof nodeDat.values === "undefined"){
            return {
                gradientIsCreated: false,
                color: fillFunction(nodeDat.deMorganPriorBelief, nodeDat.reversecolor)
            };
        }
        var DEMORGAN_GRADIENT_PREFIX = "deMorganGradient_";
        var gradientId = `${DEMORGAN_GRADIENT_PREFIX}${nodeDat.id}`;
        var gradient = d3.select("defs").select(`#${gradientId}`);
        if (gradient.size() === 0) {
            gradient = d3.select("defs")
                    .append("linearGradient")
                    .attr("id", gradientId)
                    .attr("dynamicGradient", "true")
                    .attr("x1", "0%")
                    .attr("y1", "0%")
                    .attr("x2", "100%")
                    .attr("y2", "0%");
        }
        let getGradientData = function (values) {
            var grad = 0.1;
            var width = 100 / values.length;
            var gradWidth = width * grad;
            var extendDat = [];
            values.forEach((v, index, list) => {
                var start = {};
                var stop = {};
                if (index === 0) {
                    start = {
                        value: v.value,
                        offset: 0
                    };
                    stop = {
                        value: v.value,
                        offset: width - (gradWidth / 2)
                    };
                } else if (index === (list.length - 1)) {
                    start = {
                        value: v.value,
                        offset: (index * width) + gradWidth / 2
                    };
                    stop = {
                        value: v.value,
                        offset: 100
                    };
                } else {
                    start = {
                        value: v.value,
                        offset: (index * width) + gradWidth / 2
                    };
                    stop = {
                        value: v.value,
                        offset: ((index + 1) * width) - gradWidth / 2
                    };
                }
                extendDat.push(start);
                extendDat.push(stop);
            });
            return extendDat;
        };
        //add if necessary
        gradient.selectAll("stop")
                .data(getGradientData(nodeDat.values.filter((d,i) => i%2 === 0)))
                .enter()
                .append("stop")
                .attr("offset", (d, index, divs) => `${d.offset}%`)
                .style("stop-opacity", "1");
        //update
        gradient.selectAll("stop")
                .style("stop-color", d => fillFunction(d.value, nodeDat.reversecolor));
        return {
            gradientIsCreated: true,
            def: gradient
        };
    },
    AreaChart: {
        customTip: null,
        initTip: function () {
            if (this.customTip !== null)
                return this.customTip;
            this.customTip = d3.select("#content")
                    .append("div")
                    .attr("id", "areaChTooltip")
                    .classed("d3-pie-tip n", true)
                    .style("position", "absolute")
                    .style("opacity", 1)
                    .style("overflow", "scroll")
                    .style("max-height", "80vh")
                    .style("pointer-events", "none")
                    .style("top", 0)
                    .style("left", 0)
                    .style("box-sizing", "border-box")
                    .style("transform", "translateY(-20px)");
            return this.customTip;
        },
        showTooltip: function (html, x, y) {
            var cursorMargin = 15;
            this.customTip.style("visibility", "visible")
                    .style("pointer-events", "none")
                    .style("overflow", null)
                    .style("top", y + "px")
                    .style("left", (x + cursorMargin) + "px")
                    .on("mouseenter", null)
                    .on("mouseleave", null);
            this.customTip.html(html);
        },
        config: {
            color: {
                spread: 240,
                start: 240,
                brightness: 0.5
            }
        },
        colorRange: function (index, length, colorProp) {
            var defColor = Utilities.deepCopy(this.config.color);
            var prop = undefined;
            for (prop in colorProp) {
                defColor[prop] = colorProp[prop];
            }
            var h = (defColor.start + defColor.spread * index / length) % 360;
            return "hsl(" + h + ",100%, " + (defColor.brightness * 100) + "%)";
        },
        // Area charts
        /**
         * @private
         * @param {type} node
         * @returns {undefined}
         */
        getStackGenerator: function (node) {
            if (node.temporalType !== NODE_TEMPORAL_TYPE.PLATE) {
                console.error("node isn't temporal type = NODE_TEMPORAL_TYPE.PLATE");
                return;
            }
            var values = node.values.map(a => a.value);
            var outcome = node.outcome.map(o => o);
            var stack = d3.stack().keys(outcome)
                    .order(d3.stackOrderReverse)
                    .offset(d3.stackOffsetNone);
            var data = this.getDataForStack(values, outcome);
            return stack(data);
        },
        getBarChartStackGenerator: function (node, outcomeID) {
            //find outcome handle
            var outcomeHandle = -1;
            for (var i = 0; i < node.outcome.length; i++) {
                if (node.outcome[i] === outcomeID) {
                    outcomeHandle = i;
                }
            }
            if (outcomeHandle === -1) {
                console.error("outcome name error");
                return;
            }
            //get value for selected outcome
            var values = [];
            for (var i = outcomeHandle; i < node.values.length; i += node.outcome.length) {
                values.push(node.values[i].value);
            }
            //get stack
            var stack = d3.stack().keys([outcomeID])
                    .order(d3.stackOrderReverse)
                    .offset(d3.stackOffsetNone);
            var data = this.getDataForStack(values, [outcomeID]);
            var s = stack(data);
            var allStack = this.getStackGenerator(node);
            var originalStack = allStack.find(d => d.key === outcomeID);
            s[0].index = originalStack.index;
            return {
                originalAllStack: allStack,
                reverseSinglSetack: s
            };
        },
        getDataForStack: function (values, outcome) {
            let data = [];
            var i = 0;
            var s = 0;
            while (i < values.length) {
                let row = new Object();
                if (s >= DynamicBN.stepCount) {
                    console.error("'s' is larger than slices");
                    return;
                }
                row.slice = s;
                s++;
                for (var j = 0; j < outcome.length; j++) {
                    if (i >= values.length) {
                        console.error("'i' is larger than values length");
                        return;
                    }
                    row[outcome[j]] = values[i];
                    i++;
                }
                data.push(row);
            }
            return data;
        },
        createAreaChartOnly: function (container, node, outcomeID, positionX, positionY, widthChart, heightChart) {//in bar charts
            var margin = {top: 0.5, right: 0.5, bottom: 0.5, left: 0.5, between: 0},
                    width = widthChart - margin.left - margin.right,
                    height = heightChart - margin.top - margin.bottom;
            var stack = this.getBarChartStackGenerator(node, outcomeID);
            var stackColor = this.getStackColorFunction(node, stack.originalAllStack);
            //area chart
            var x = d3.scaleLinear().range([0, width]),
                    y = d3.scaleLinear().range([height, 0]),
                    color = stackColor.color;

            var area = d3.area()
                    .x(d => x(d.data.slice))
                    .y0(d => y(d[0]))
                    .y1(d => y(d[1]));

            var g = container.append("g")
                    .attr("class", "areaChart")
                    .attr("transform", Utilities.getTransformString(positionX,positionY));

            //background
            g.append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("width", widthChart)
                    .attr("height", heightChart)
                    .attr("fill", "white")
                    .style('stroke', "black")
                    .style("stroke-width", 0.5);

            x.domain([0, DynamicBN.stepCount - 1]);
            //clear all data
            g.selectAll(".layer")
                    .data([]).exit().remove();
            //add new data
            var update = g.selectAll(".layer")
                    .data(stack.reverseSinglSetack);
            var layer = update.enter().append("g")
                    .attr("class", "layer")
                    .attr("transform", Utilities.getTransformString(margin.left,margin.top));;
            layer.append("path")
                    .attr("class", "area")
                    .style("fill", d => color(d.index))
                    .attr("d", area);
        },
        getOutcomeIndex: function (outcomes, outcomeID) {
            for (var i = 0; i < outcomes.length; i++) {
                if (outcomes[i] === outcomeID) {
                    return i;
                }
            }
            return 0;
        },
        getStackColorFunction: function (node, stack) {
            stack = typeof stack === "undefined" ? this.getStackGenerator(node) : stack;
            //chart height dependent on number of outcomes
            var color = d3.scaleOrdinal()
                    .domain(stack.map(d => d.index))
                    .range(stack.map(d => this.colorRange(d.index, stack.length)));
            return {
                stack: stack,
                color: color
            };
        },
        createAreaChart: function (container, node, conf) {
            var configuration = {
                showLegend: true,
                showXAxis: true,
                xAxisClass: "",
                showYAxis: false,
                yAxisClass: "",
                width: -1,
                height: -1,
                containerCouldInvisible: true,
                showExtraValueLine: false,
                margin: {},
                labelFont: {
                    color: "",
                    size: "0.8em"
                }
            };
            var prop = undefined;
            for (prop in conf) {
                configuration[prop] = conf[prop];
            }
            var margin = {top: 5, right: 2, bottom: 3, left: 5, between: 0},
                    width = 150 - margin.left - margin.right,
                    height = margin.top;
            if(configuration.width > 0){
                width = configuration.width - margin.left - margin.right;
            }
            prop = undefined;
            for (prop in configuration.margin) {
                margin[prop] = configuration.margin[prop];
            }
            var stackColor = this.getStackColorFunction(node);
            var stack = stackColor.stack;
            var color = stackColor.color;
            //chart height dependent on number of outcomes
            var outcome = node.outcome.map(o => o);
            var squareLegendSize = 10;
            for (var i = 0; i < outcome.length; i++) {
                height += squareLegendSize + margin.top;
            }
            var svgChart;
            if (container.selectAll("svg").size() > 0) {
                svgChart = container.select("svg");
            } else {
                svgChart = container.append("svg");
            }
            var yAxisWidth = 0;
            if(configuration.showYAxis){
                let yTmp = d3.scaleLinear().range([100, 0]).domain([0, 1]);
                let GYTmp = svgChart.selectAll("g.axis--yTmp").data([yTmp.domain()]).enter().append("g")
                        .attr("class", `axis axis--yTmp ${configuration.yAxisClass}`)
                        .call(d3.axisLeft(yTmp).ticks(3, "%"));
                let yAxisSize = svgChart.select("g.axis--yTmp").node().getBBox();
                yAxisWidth = yAxisSize.width;
                GYTmp.remove();
            }
            //legend
            if (configuration.showLegend) {
                var legendUpdate = svgChart.selectAll('.legend')
                        .data(stack);
                var enter = legendUpdate.enter()
                        .append("g")
                        .attr("class", "legend")
                        .attr("transform", (d, i) => Utilities.getTransformString(margin.left, (i * (10 + margin.top) + margin.top)));

                enter.append('rect')
                        .attr("x", 0)
                        .attr("y", 0)
                        .attr("width", 10)
                        .attr("height", 10)
                        .style("fill", d => color(d.index));

                enter.append('text')
                        .attr("x", 15)
                        .attr("y", 10)
//            .attr("dy", ".35em")
                        .text(d => d.key)
                        .attr("class", "textselected")
                        .style("text-anchor", "start")
                        .style("fill", configuration.labelFont.color)
                        .style("font-size", configuration.labelFont.size);
            }

            var legendWidth = d3.max(svgChart.selectAll("g.legend").nodes().map(a => a.getBoundingClientRect().width));
            if(!configuration.showLegend){
                legendWidth = 0;
            }
            var containerSize = container.node().getBoundingClientRect();//możliwe że container jest ukryty
            if (legendWidth === 0 && configuration.containerCouldInvisible && containerSize.width === 0 && containerSize.height === 0) {
                var displayInfo = RightColumn.Accordion.displayHiddenCard(container.node(),Node.getNodeIdFromObject(node));
                legendWidth = d3.max(svgChart.selectAll("g.legend").nodes().map(a => a.getBoundingClientRect().width));
                containerSize = container.node().getBoundingClientRect();
                RightColumn.Accordion.retractCardStates(displayInfo);
            }
            // swap text with rectangle legend
//    svgChart.selectAll("text").attr("x",0);
//    svgChart.selectAll("rect").attr("x",legendWidth-svgChart.select("rect").attr("width"));
            if (configuration.showLegend){
                legendUpdate.exit().remove();
            }

            var svgHeight = (height + margin.top + margin.bottom);
            if(configuration.height > 0){
                svgHeight = configuration.height + margin.top + margin.bottom;
                height = configuration.height;
            }

            if (configuration.width > 0) {//tooltip only //stała szerokość
                var svgWidth = (legendWidth + width + margin.left + margin.right);
                svgChart.attr("width", svgWidth)
                        .attr("height", svgHeight);
            } else if (!svgChart.attr("viewBox")) {//first create area chart
                width = containerSize.width - margin.left - margin.right - legendWidth - yAxisWidth;
                // add responsive class to the container if don't exist
                container.classed("svg-container", true);// true meaning - add class
                container.classed("mycard-block", false);

                // add responsive class and attributes to the SVG inside container if don't exist
                svgChart.attr("preserveAspectRatio", "xMidYMid meet");
                svgChart.classed("svg-content", true);

                // set view box
                svgChart.attr("viewBox", "0 0 " + containerSize.width + " " + svgHeight)
                        .attr("chartWidth", width)
                        .attr("chartHeight", height);
                let paddingButtomRatio = 100 / ((containerSize.width - yAxisWidth) / svgHeight);
                container.style("padding-bottom", paddingButtomRatio + "%");
            } else {//update area chart
                width = parseFloat(svgChart.attr("chartWidth"));
                height = parseFloat(svgChart.attr("chartHeight"));
            }

            //area chart
            var x = d3.scaleLinear().range([0, width]),
                    y = d3.scaleLinear().range([height, 0]);

            var area = d3.area()
                    .x(d => x(d.data.slice))
                    .y0(d => y(d[0]))
                    .y1(d => y(d[1]));

            var g;
            if (svgChart.selectAll("g.areaChart").size() > 0) {
                g = svgChart.select("g.areaChart");
            } else {
                g = svgChart.append("g")
                        .attr("class", "areaChart")
                        .attr("transform", Utilities.getTransformString((margin.left + legendWidth + margin.between + yAxisWidth), margin.top));
            }

            x.domain([0, DynamicBN.stepCount - 1]);
            y.domain([0, 1]);
            //clear all data
            g.selectAll(".layer")
                    .data([]).exit().remove();
            //add new data
            var update = g.selectAll(".layer")
                    .data(stack);
            var layer = update.enter().append("g")
                    .attr("class", "layer");
            layer.append("path")
                    .attr("class", "area")
                    .style("fill", d => color(d.index))
                    .attr("d", area);
            if (configuration.showXAxis) {
                g.selectAll("g.axis--x").data([x.domain()]).enter().append("g")
                        .attr("class", `axis axis--x ${configuration.xAxisClass}`)
                        .attr("transform", Utilities.getTransformString(0, height))
                        .call(d3.axisBottom(x));
            }
            if (configuration.showYAxis) {
                g.selectAll("g.axis--y").data([y.domain()]).enter().append("g")
                        .attr("class", `axis axis--y ${configuration.yAxisClass}`)
                        .attr("transform", Utilities.getTransformString(0, 0))
                        .call(d3.axisLeft(y).ticks(10, "%"));
            }
            if (configuration.showExtraValueLine) {
                this.initTip();
                var focus = svgChart.append("g")
                        .attr("class", "focus")
                        .style("display", "none");

                focus.append("line")
                        .attr("x1", 0)
                        .attr("y1", margin.top)
                        .attr("x2", 0)
                        .attr("y2", margin.top + height)
                        .style("stroke", "black");

                focus.append("text")
                        .attr("x", 9)
                        .attr("dy", ".35em")
                        .style("font-size", 15);
                svgChart.append("rect")
                        .attr("class", "overlay")
                        .attr("width", width)
                        .attr("height", height)
                        .attr("transform", Utilities.getTransformString((margin.left + legendWidth + margin.between + yAxisWidth), margin.top))
                        .on("mouseover", function () {
                            focus.style("display", null);
                        })
                        .on("mouseout", function () {
                            focus.style("display", "none");
                            DynamicBN.AreaChart.customTip.style("visibility", "hidden");
                        })
                        .on("mousemove", mousemove);

                function mousemove() {

                    var x0 = x.invert(d3.mouse(this)[0]);
                    var node = d3.select(this).data()[0].node;
                    var values = node.values.map(a => a.value);
                    var outcome = node.outcome.map(o => o);
                    var data = DynamicBN.AreaChart.getDataForStack(values, outcome);
                    var i = Math.round(x0);
                    var d = data[i];
                    var html = "";
                    for (const [key, value] of Object.entries(d)) {
                        html += `${key}: ${value}</br>`;
                    }
                    DynamicBN.AreaChart.showTooltip(html, event.pageX, event.pageY);
                    focus.attr("transform", "translate(" + ((margin.left + legendWidth + margin.between + yAxisWidth) + x(d.slice)) + "," + 0 + ")");
                }
            }
            return {
                area: area
            };
        }
    }
};

export default DynamicBN;
